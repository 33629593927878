import { ReactNode, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { lineClampStyles } from "../../../util/style.util";

export type VerticalToggleMode = "children" | "content";

export type ListMenuItem<K extends string | number> = {
  key: K;
  prefix: string;
  content: string;
  endIcon?: ReactNode;
  disableSelect?: boolean;
  defaultColor?: { background: string; content: string };
  children?: Omit<ListMenuItem<K>, "children">[];
  verticalToggleMode?: VerticalToggleMode;
};

const ListMenuRow = <K extends string | number>({
  item,
  isSelected,
  toggleSelect,
  nested,
  compact,
  areChildrenExpanded,
  isContentTruncated = true,
  verticalToggle,
}: {
  item: ListMenuItem<K>;
  isSelected: boolean;
  toggleSelect: () => void;
  nested?: boolean;
  compact?: boolean;
  areChildrenExpanded?: boolean;
  isContentTruncated?: boolean;
  verticalToggle?: {
    mode: VerticalToggleMode;
    perform: () => void;
  };
}) => {
  const isParent = !!item.children;
  const horizontalSpacing = compact ? 1 : 1.5;
  const selectedBgColor = isParent ? "surface.dark" : "primary.light";
  const selectedTextColor = isParent ? "surface.contrastText" : "text.primary";
  const canToggleChildren = verticalToggle?.mode === "children";
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={item.disableSelect ? undefined : toggleSelect}
      key={item.key}
      sx={{
        width: "100%",
        px: horizontalSpacing,
        py: nested && !compact ? 2 : 1,
        minHeight: 56,
        borderRadius: compact ? undefined : 1,
        bgcolor: isSelected ? selectedBgColor : undefined,
        color: isSelected ? selectedTextColor : undefined,
        cursor: "pointer",
        borderTop: 1,
        borderColor: "grey.200",
      }}
      gap={horizontalSpacing}
    >
      <Stack
        direction="row"
        sx={{ flexShrink: 0, width: compact || !nested ? 24 : 36 }}
        justifyContent="center"
        onClick={e => {
          if (!canToggleChildren) return;
          verticalToggle.perform();
          e.stopPropagation();
        }}
      >
        {canToggleChildren && (areChildrenExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />)}
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {item.prefix}
        </Typography>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="body2"
          sx={{ ...(isContentTruncated ? lineClampStyles(2) : undefined), fontWeight: isParent ? "bold" : undefined }}
        >
          {item.content}
        </Typography>
      </Box>
      {(verticalToggle?.mode === "content" || item.endIcon) && (
        <Stack direction="row" gap={0.5} sx={{ flexShrink: 0 }}>
          {verticalToggle?.mode === "content" &&
            (isContentTruncated ? (
              <KeyboardArrowDownIcon onClick={verticalToggle.perform} />
            ) : (
              <KeyboardArrowUpIcon onClick={verticalToggle.perform} />
            ))}
          {item.endIcon}
        </Stack>
      )}
    </Stack>
  );
};

const ListMenuAccordion = <K extends string | number>({
  item,
  selectedKey,
  onSelect,
  compact,
}: {
  item: ListMenuItem<K>;
  selectedKey?: K;
  onSelect: (key?: K) => void;
  compact?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isParentTruncated, setIsParentTruncated] = useState(true);

  let verticalTogglePerform: (() => void) | undefined = undefined;
  switch (item.verticalToggleMode) {
    case "children":
      verticalTogglePerform = () => setIsExpanded(!isExpanded);
      break;
    case "content":
      verticalTogglePerform = () => setIsParentTruncated(!isParentTruncated);
      break;
  }
  const verticalToggle =
    item.verticalToggleMode && verticalTogglePerform
      ? { mode: item.verticalToggleMode, perform: verticalTogglePerform }
      : undefined;

  const toggleSelect = (key: K) => () => (key === selectedKey ? onSelect(undefined) : onSelect(key));

  return (
    <Accordion
      expanded={isExpanded}
      square
      elevation={0}
      sx={{
        borderBottom: 1,
        borderColor: "grey.200",
        "& .MuiAccordionSummary-content.Mui-expanded": { margin: 0 },
        "& .MuiAccordionSummary-content": { margin: 0 },
      }}
    >
      <AccordionSummary sx={{ p: 0 }}>
        <ListMenuRow
          nested
          compact={compact}
          item={item}
          toggleSelect={toggleSelect(item.key)}
          isSelected={item.key === selectedKey}
          areChildrenExpanded={isExpanded}
          isContentTruncated={isParentTruncated}
          verticalToggle={verticalToggle}
        />
      </AccordionSummary>
      {item.children && (
        <AccordionDetails sx={{ p: 0 }}>
          <Box>
            {item.children.map(childItem => (
              <ListMenuRow
                nested
                compact={compact}
                item={childItem}
                toggleSelect={toggleSelect(childItem.key)}
                isSelected={childItem.key === selectedKey}
                key={childItem.key}
                isContentTruncated={!compact}
              />
            ))}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const ListMenu = <K extends string | number>({
  items,
  selectedKey,
  onSelect,
  nested,
  compact,
}: {
  items: ListMenuItem<K>[];
  selectedKey?: K;
  onSelect: (key?: K) => void;
  nested?: boolean;
  compact?: boolean;
}) => {
  const toggleSelect = (key: K) => () => (key === selectedKey ? onSelect(undefined) : onSelect(key));
  return (
    <Stack>
      {items.map(item =>
        nested ? (
          <ListMenuAccordion
            item={item}
            onSelect={onSelect}
            selectedKey={selectedKey}
            compact={compact}
            key={item.key}
          />
        ) : (
          <Box sx={{ width: "100%", py: 0, borderBottom: 1, borderColor: "grey.200" }} key={item.key}>
            <ListMenuRow item={item} toggleSelect={toggleSelect(item.key)} isSelected={item.key === selectedKey} />
          </Box>
        ),
      )}
    </Stack>
  );
};

export default ListMenu;
