import netlessSlide from "@netless/app-slide";
import { FastboardApp, createFastboard, apps } from "@netless/fastboard";
import * as Icons from "ionicons/icons";
import { makeAutoObservable, runInAction } from "mobx";
import { Room as AgoraRoom, RoomPhase } from "white-web-sdk";
import { WhiteboardRoom } from "@parallel/vertex/types/whiteboard.types";
import { processWithTimeout } from "@parallel/vertex/util/async.util";
import { WhiteboardAPI } from "@/api/whiteboard.api";
import config from "@/config";
import ConnectionState, { isStateValid } from "@/interfaces/ConnectionState";
import { FileUploadApp } from "@/plugins/fastboard/FileUploadNetlessComponent";
import { MeetingStore } from "@/stores/meeting.store";
import { TelehealthLogger } from "@/utils/logging.utils";
import { UserStore } from "./user.store";

export class WhiteboardStore {
  connectionState: ConnectionState = ConnectionState.Ready;
  room?: WhiteboardRoom = undefined;
  fastboardApp?: FastboardApp = undefined;
  agoraRoom?: AgoraRoom = undefined;
  agoraRoomPhase: RoomPhase = RoomPhase.Connecting;

  errorMessage?: string = undefined;

  constructor(
    private userStore: UserStore,
    private meetingStore: MeetingStore,
    private whiteboardApi: WhiteboardAPI,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  private isStateValid = isStateValid(() => this.connectionState, this.logger);

  async connectToRoom({
    whiteboardRoom,
    participantKey,
    timeoutMs,
  }: {
    whiteboardRoom: WhiteboardRoom;
    participantKey: string;
    timeoutMs?: number;
  }) {
    this.connectionState = ConnectionState.Connecting;
    this.errorMessage = undefined;
    const fastboardApp = await processWithTimeout(
      "init fastboard",
      () =>
        createFastboard({
          sdkConfig: {
            appIdentifier: config.agoraAppIds.whiteboard,
            region: "us-sv",
          },
          joinRoom: {
            uid: participantKey,
            uuid: whiteboardRoom.roomUid,
            roomToken: whiteboardRoom.roomToken,
          },
          managerConfig: {
            cursor: this.userStore.userId !== "watcher", // prevent showing agora webrecorder whiteboard cursor
            disableCameraTransform: !this.userStore.isStaff,
          },
          netlessApps: [FileUploadApp, netlessSlide],
        }),
      timeoutMs,
    );
    if (this.userStore.isStaff) {
      apps.push({
        icon: Icons.document,
        kind: FileUploadApp.kind,
        label: "Upload Files",
        onClick(fastboardApp) {
          fastboardApp.manager.addApp({ kind: FileUploadApp.kind });
        },
      });
    }
    runInAction(() => {
      this.connectionState = ConnectionState.Connected;
      this.fastboardApp = fastboardApp;
    });
    return fastboardApp;
  }

  async prepareRoom({ appointmentId, userId }: { appointmentId: string; userId: string }) {
    if (!this.isStateValid("connect", [ConnectionState.Ready, ConnectionState.Error])) return;
    this.connectionState = ConnectionState.Connecting;
    const room = await this.whiteboardApi.prepareWhiteboardRoom(appointmentId, userId);
    runInAction(() => {
      this.connectionState = ConnectionState.Connected;
      this.room = room;
    });
    this.logger.info("[WhiteboardStore] finished old room prepare", room);
  }

  async disconnect() {
    this.room = undefined;
    this?.fastboardApp?.destroy();
    this.fastboardApp = undefined;
    this?.agoraRoom?.delete();
    this.agoraRoom = undefined;
    this.agoraRoomPhase = RoomPhase.Connecting;
    this.connectionState = ConnectionState.Ready;
  }

  async joinAgoraFastboard() {
    if (!this.isStateValid("joinAgoraFastboard", [ConnectionState.Connected])) return;
    if (this.fastboardApp?.phase.value === "connected") {
      this.logger.operationSuccess("joinAgoraFastboard", {
        room: this.room,
        fromCache: true,
      });
      return this.fastboardApp;
    }
    if (!this.room || !this.meetingStore.connectedParticipantKey) return;

    await this.fastboardApp
      ?.destroy()
      .catch(e => this.logger.error("error deleting disconnected fastboard app", null, e));

    const fastboardApp = await this.connectToRoom({
      whiteboardRoom: this.room,
      participantKey: this.meetingStore.connectedParticipantKey,
    });
    runInAction(() => (this.fastboardApp = fastboardApp));

    this.logger.operationSuccess("joinAgoraFastboard", {
      room: this.room,
      fromCache: false,
    });

    return fastboardApp;
  }

  agoraWhiteboardPhaseChange(newPhase: RoomPhase) {
    this.agoraRoomPhase = newPhase;
  }
}
