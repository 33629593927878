import { useContext, useEffect, useState } from "react";
import { DailyCall } from "@daily-co/daily-js";
import { useAppMessage, useLocalSessionId, useParticipantProperty } from "@daily-co/daily-react";
import { omit } from "lodash";
import { observer } from "mobx-react-lite";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import DailyParticipantMedia from "@/components/meeting/daily/DailyParticipantMedia";
import { StoreContext, loggerContext } from "@/stores";
import { encodeUserName, useAppMessageCallback, useInitialDailyStateSync } from "@/utils/daily.utils";
import { DailyMeetingConnectionParams, useDailyMeetingConnection } from "@/utils/hooks/use-meeting-connection";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";
import AppointmentWaitingRoom from "./AppointmentWaitingRoom";

const logger = initLogger("DailyAppointmentWaitingRoom", loggerContext);

const DailyVideoPreview = ({ localDailySessionId }: { localDailySessionId: string }) => {
  const [localVideoTrack] = useParticipantProperty(localDailySessionId, ["tracks.video"]);
  return (
    <div className="w-full h-full">
      <DailyParticipantMedia video={localVideoTrack.persistentTrack} />
    </div>
  );
};

const DailyAppointmentWaitingRoom = (props: {
  dailyCall: DailyCall;
  userId: string;
  appointment: ExtendedAppointment;
  participantKey: string;
}) => {
  const { dailyCall, userId, appointment, participantKey } = props;
  const { userStore } = useContext(StoreContext);
  const [accessRequestErrorMessage, setAccessRequestErrorMessage] = useState("");

  const connectionParams: DailyMeetingConnectionParams = {
    dailyCall,
    meetingKey: appointment.appointmentId,
    participantKey,
    user: { id: userId, name: userStore.fullName, type: "client" },
    isWaiting: true,
  };
  const { isFinished, error: connectionError, userName } = useDailyMeetingConnection(connectionParams);
  const sendAppMessage = useAppMessage({
    onAppMessage: useAppMessageCallback(dailyCall, participantKey, appointment.appointmentId, logger),
  });
  const localParticipantId = useLocalSessionId();
  useInitialDailyStateSync(isFinished, sendAppMessage, localParticipantId, true);

  let error = connectionError;
  if (accessRequestErrorMessage) error ||= { message: accessRequestErrorMessage };

  let status: "loading" | "connected" | "error" = "loading";
  if (isFinished) status = error ? "error" : "connected";

  const redirect = useEventRedirect(logger);
  const logContext = {
    props: omit(props, "dailyCall"),
    connectionParams: omit(props, "dailyCall"),
  };

  useEffect(() => {
    if (!isFinished || !!connectionError) return;
    dailyCall
      .requestAccess({ name: encodeUserName({ userName, userId }), access: { level: "full" } })
      .then(({ granted }) => {
        granted
          ? redirect(`/meeting/appointment/${appointment.appointmentId}/v2`, "access granted")
          : setAccessRequestErrorMessage("Access Rejected");
      })
      .catch(e => {
        dailyCall.leave();
        setAccessRequestErrorMessage("Access Request Failed");
        logger.error("meeting access request failed", logContext, e);
      });
  }, [isFinished]);

  const localVideo =
    (localParticipantId && <DailyVideoPreview localDailySessionId={localParticipantId} />) || undefined;

  useEffect(() => {
    if (!isFinished) return;
    logger.postEvent("PageReady", "waiting room ready", logContext);
  }, [isFinished]);

  return <AppointmentWaitingRoom appointment={appointment} status={status} localVideo={localVideo} error={error} />;
};

export default observer(DailyAppointmentWaitingRoom);
