import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const MonospaceValueInput = styled(TextField)({
  "& .MuiInputBase-root": {
    fontFamily: "monospace",
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
  },
});

export const SmallValueInput = styled(MonospaceValueInput)({
  "& .MuiInputBase-root": {
    height: 30,
    width: 60,
  },
});

export const LargeValueInput = styled(MonospaceValueInput)({
  "& .MuiInputBase-root": {
    fontSize: 32,
  },
});
