import { useContext, useEffect, useRef, useState } from "react";
import { UI, createUI, apps as pluginApps } from "@netless/fastboard";
import { observer } from "mobx-react-lite";
import { ViewMode, ApplianceNames } from "white-web-sdk";
import { loggerContext, StoreContext } from "@/stores";
import { classNames } from "@/utils";
import { hexToRgb } from "@/utils/color";
import { initLogger } from "@/utils/logging.utils";

const OMIT_PLUGIN_KINDS = ["Monaco", "GeoGebra"];

const logger = initLogger("AgoraFastboard", loggerContext);

const AgoraFastboard = () => {
  const { participantStore, userStore, whiteboardStore } = useContext(StoreContext);
  const { connectionState, fastboardApp } = whiteboardStore;
  const { isStaff: isRoomLeader } = userStore;

  const canWrite = participantStore.localParticipant?.whiteboardMode === "write" || isRoomLeader;
  const whiteboardColorHex = participantStore.localParticipantWhiteboardColor;

  const containerRef = useRef(null);
  const [fastboardUI, setFastboardUI] = useState<UI>();

  const updateUIConfig = (ui: UI) =>
    ui.update({
      config: {
        toolbar: { enable: canWrite },
        redo_undo: { enable: canWrite },
        zoom_control: { enable: isRoomLeader },
        page_control: { enable: isRoomLeader },
      },
    });

  useEffect(() => {
    if (!containerRef.current) return;
    const container = containerRef.current;
    let ignore = false;
    (async () => {
      try {
        const app = await whiteboardStore.joinAgoraFastboard();
        if (!app || ignore) return;

        pluginApps.delete(a => OMIT_PLUGIN_KINDS.includes(a.kind));
        if (whiteboardColorHex) {
          const { r, g, b } = hexToRgb(whiteboardColorHex);
          app.setStrokeColor([r, g, b]);
        }
        app.manager.setViewMode(isRoomLeader ? ViewMode.Broadcaster : ViewMode.Follower);
        // toggle a tiny zoom so view syncs with the rest of the participants
        if (isRoomLeader) {
          const scale = app.manager.camera.scale;
          app.manager.moveCamera({ scale: scale + 0.01 });
          app.manager.moveCamera({ scale });
        }
        const ui = createUI(app, container);
        updateUIConfig(ui);
        setFastboardUI(ui);
        logger.postEvent("ComponentReady", "whiteboard ready", undefined, "Whiteboard");
      } catch (e: any) {
        logger.postEvent("Warning", "error displaying whiteboard", { causedBy: e.message }, "Whiteboard");
      }
    })();
    return () => {
      ignore = true;
    };
  }, [containerRef, connectionState]);

  const connectionStatus = fastboardApp?.phase.value;
  useEffect(() => {
    if (!fastboardApp || !fastboardUI) return;
    updateUIConfig(fastboardUI);
    fastboardApp.manager.setReadonly(!canWrite);
    if (connectionStatus !== "connected") return;
    if (canWrite) {
      fastboardApp.setAppliance(ApplianceNames.pencil);
    } else {
      fastboardApp.setAppliance(ApplianceNames.clicker);
    }
  }, [fastboardApp, fastboardUI, canWrite, connectionStatus]);

  return (
    <div
      // these classes let us hide certain fastboard tool buttons for clients only in `index.css`
      className={classNames(
        "w-full h-full bg-slate-500 rounded-xl fastboard-container",
        isRoomLeader ? "provider" : "client",
      )}
      ref={containerRef}
      role="region"
      aria-label="whiteboard-container"
    />
  );
};

export default observer(AgoraFastboard);
