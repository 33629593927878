const STAGING_PROJECT = "parallel-staging-352118";
const PROD_PROJECT = "parallel-prod";

const FIREBASE_SITES = [
  { host: "pathway-s", project: STAGING_PROJECT, nexusService: "pathway" },
  { host: "report-writer-s", project: STAGING_PROJECT, nexusService: "org" },
  { host: "telehealth-s", project: STAGING_PROJECT, nexusService: "telehealth" },
  { host: "pathway-p", project: PROD_PROJECT, nexusService: "pathway" },
  { host: "report-writer-p", project: PROD_PROJECT, nexusService: "org" },
  { host: "telehealth-p", project: PROD_PROJECT, nexusService: "telehealth" },
];

const NEXUS_VERSION_ALT_HOST_MATCHER = new RegExp(
  `(${FIREBASE_SITES.map(s => s.host).join("|")})--nexus-(\\w+)-\\w+\\.web\\.app`,
);

/**
 * if this client is hosted at an firebase alternate domain containing a nexus server version,
 *  derive + return the nexus server version url
 */
export const resolveAlternateDomainNexusServerUrl = () => {
  const nexusVersionAltHostMatch = window.location.host.match(NEXUS_VERSION_ALT_HOST_MATCHER);
  if (!nexusVersionAltHostMatch) return;

  const hostMatch = nexusVersionAltHostMatch[1];
  const firebaseSite = FIREBASE_SITES.find(s => s.host === hostMatch);
  if (!firebaseSite) return;

  const nexusServicePart = `-dot-${firebaseSite.nexusService}`;
  const projectPart = `-dot-${firebaseSite.project}`;

  const nexusVersionPart = nexusVersionAltHostMatch[2];

  return `https://${nexusVersionPart}${nexusServicePart}${projectPart}.uc.r.appspot.com`;
};
