import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { SimpleHeaderLayout } from "@/components/common/layout/HeaderLayout";
import SingleAppointmentLobby from "@/components/lobby/SingleAppointmentLobby";
import UpcomingAppointmentsLobby from "@/components/lobby/UpcomingAppointmentsLobby";
import useQuery from "@/utils/use-query";

const LobbyScreen = () => {
  const queryParams = useQuery();

  let { shortCode } = useParams<{ shortCode: string }>();
  shortCode ||= queryParams.get("code") || undefined;

  return (
    <SimpleHeaderLayout>
      {shortCode ? <SingleAppointmentLobby appointmentCode={shortCode} /> : <UpcomingAppointmentsLobby />}
    </SimpleHeaderLayout>
  );
};

export default observer(LobbyScreen);
