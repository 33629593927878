import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { IonIcon } from "@ionic/react";
import { useKeyboardEvent } from "@react-hookz/web";
import { search as searchIcon, closeOutline as closeIcon, chevronForwardOutline as groupIcon } from "ionicons/icons";
import { dropRight, last, take } from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import {
  AssessmentTestSelection,
  AssessmentStimulusTestGroup,
} from "@parallel/vertex/types/assessment/assessment.testing.types";
import { StoreContext } from "@/stores";
import { AssessmentStore } from "@/stores/assessment.store";

const rootAssessmentGroup = (filteredMetadata: AssessmentStimulusTestGroup[] = []): AssessmentStimulusTestGroup => ({
  name: "All Assessments",
  subgroups: filteredMetadata,
  availableTests: [],
});

const matchesString = (matchesCount: number) =>
  matchesCount === 1 ? `${matchesCount} Match` : `${matchesCount} Matches`;

// hide any group that contains no tests anywhere in its tree and with a name not matching the filter string
const shouldDisplayGroup =
  (a: AssessmentStore) =>
  (g: AssessmentStimulusTestGroup): boolean =>
    g.availableTests.length > 0 || a.isSearchMatch(g.name) || !!g.subgroups.find(shouldDisplayGroup(a));

const useAssessmentGroups = (
  assessmentStore: AssessmentStore,
  all: AssessmentStimulusTestGroup[],
  selected?: AssessmentTestSelection,
) => {
  const [browseGroupNames, setBrowseGroupNames] = useState<string[]>([]);

  // update browse group when `selectedAssessment` changes
  useEffect(() => {
    if (!selected) return;
    setBrowseGroupNames(selected.parentGroupNames || []);
  }, [selected]);

  const groups = [rootAssessmentGroup(all), ...(assessmentStore.getGroupPath(browseGroupNames) || [])];
  const currGroup = last(groups) || rootAssessmentGroup();
  currGroup.subgroups = currGroup.subgroups.filter(shouldDisplayGroup(assessmentStore));
  return {
    currGroup: last(groups) || rootAssessmentGroup(),
    prevGroups: dropRight(groups, 1).map((g, i) => ({
      name: g.name,
      select: () => setBrowseGroupNames(take(browseGroupNames, i)),
    })),
    append: (g: AssessmentStimulusTestGroup) => setBrowseGroupNames([...browseGroupNames, g.name]),
  };
};

const MenuItemButton = ({
  name,
  onClick,
  isGroup,
  isSelected = false,
  containerRef,
  note,
}: {
  name: string;
  onClick: () => void;
  isGroup: boolean;
  isSelected?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
  note?: string;
}) => {
  const [isHover, setIsHover] = useState(false);

  const colorClassName = isSelected || isHover ? "text-primary border-primary" : "text-slate-600 border-slate-200";
  const groupClassName = isGroup ? "" : "border-l-2";

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isSelected || !ref.current || !ref.current.scrollIntoView || !containerRef?.current) return;
    const { top: containerTop, bottom: containerBottom } = containerRef.current.getBoundingClientRect();
    const { top: buttonTop, bottom: buttonBottom } = ref.current.getBoundingClientRect();

    if (buttonTop > containerBottom || buttonBottom < containerTop) ref.current.scrollIntoView(true);
  }, [isSelected, ref.current]);

  return (
    <div
      onClick={onClick}
      className="w-full pl-4 pr-2 py-1 hover:bg-light cursor-pointer"
      ref={ref}
      tabIndex={0}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      role="menuitem"
    >
      <div className={`w-full py-2 pl-3 text-md text-left cursor-pointer ${groupClassName} ${colorClassName}`}>
        <div className="flex items-center w-full">
          <div className="grow">
            <p>{name}</p>
            {note && <p className="italic text-sm text-slate-500">{note}</p>}
          </div>
          {isGroup && <IonIcon icon={groupIcon} className="mr-2" />}
        </div>
      </div>
    </div>
  );
};

const AssessmentsMenu = () => {
  const {
    assessmentStore,
    activityStore: { currAssessmentTest: selectedAssessment },
  } = useContext(StoreContext);
  const { search, filteredMetadata } = assessmentStore;

  const { currGroup, prevGroups, append } = useAssessmentGroups(assessmentStore, filteredMetadata, selectedAssessment);

  let testMatchCount = search ? currGroup.availableTests.length : undefined;
  // do not show match count if zero and subgroups are showing (most likely, user is viewing a group)
  if (testMatchCount === 0 && currGroup.subgroups.length > 0) testMatchCount = undefined;

  const buttonContainerRef = useRef<HTMLDivElement>(null);

  /**
   * keyboard shortcuts
   */
  useKeyboardEvent(
    ({ key }) => key?.includes("Arrow"),
    (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowDown":
        case "ArrowRight":
          assessmentStore.selectNext();
          e.preventDefault();
          return;
        case "ArrowUp":
        case "ArrowLeft":
          assessmentStore.selectPrevious();
          e.preventDefault();
          return;
      }
    },
  );

  return (
    <div className="w-full h-full flex flex-col text-slate-700 overflow-hidden" role="menu" aria-label="assessments">
      {selectedAssessment && (
        <div className="p-4 border-b border-slate-300 bg-light" role="region" aria-label="selected-test">
          <p className="text-slate-600 text-sm">Now Showing:</p>
          <p className="text-primary text-lg mb-1 font-semibold">{selectedAssessment.name}</p>
          <button
            onClick={() => assessmentStore.setSelected(null)}
            className="my-1.5 py-0.5 px-3 text-md bg-primary text-zinc-50 rounded-md hover:bg-navy"
          >
            Stop Sharing
          </button>
        </div>
      )}
      <div className="py-3 pl-4">
        <div className="flex flex-wrap">
          {prevGroups.map(({ name, select }) => (
            <div key={name}>
              <button onClick={select} className="text-md text-slate-600 hover:text-primary" key={name}>
                {name}
              </button>
              <span className="mx-1">/</span>
            </div>
          ))}
        </div>
        <h1 className="text-xl my-1 text-slate-900" aria-label="curr-group">
          {currGroup.name}
        </h1>
        {testMatchCount !== undefined && <h3 className="text-sm text-slate-600">{matchesString(testMatchCount)}</h3>}
      </div>

      <div className="py-4 flex-grow overflow-y-auto border-y border-slate-200" ref={buttonContainerRef}>
        {currGroup.subgroups.map(g => (
          <MenuItemButton
            name={g.name}
            onClick={() => append(g)}
            isGroup={true}
            note={search ? matchesString(assessmentStore.getGroupSize(g)) : undefined}
            key={g.name}
          />
        ))}
        {currGroup.availableTests.map(a => (
          <MenuItemButton
            name={a.name || "MISSING NAME"}
            onClick={() => assessmentStore.setSelected(a)}
            isGroup={false}
            isSelected={a.id === selectedAssessment?.id}
            containerRef={buttonContainerRef}
            key={a.id}
          />
        ))}
      </div>

      <div className="relative w-full p-3">
        <div className="absolute top-0 left-5 h-full grid place-items-center">
          <IonIcon icon={searchIcon} />
        </div>
        <input
          value={search}
          name="search"
          placeholder="Search"
          onChange={event => runInAction(() => (assessmentStore.search = event.target.value))}
          className="h-12 w-full px-8 rounded-md border border-gray-200"
        />
        {!!search && (
          <div
            onClick={() => runInAction(() => (assessmentStore.search = ""))}
            className="absolute right-5 top-0 h-full grid place-items-center cursor-pointer"
          >
            <IonIcon icon={closeIcon} />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AssessmentsMenu);
