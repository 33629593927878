import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddCommentIcon from "@mui/icons-material/AddComment";
import RemoveIcon from "@mui/icons-material/Remove";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { z } from "zod";
import { roundToDecimal, toPercentString } from "@parallel/vertex/util/number.util";
import { Status, StatusIcon } from "../../shared/input/status.input";
import { SmallValueInput } from "../../shared/input/value.input";
import ObjectiveValueInput from "./ObjectiveValueInput";

export const DEFAULT_STEP_VALUES = { correct: 0, incorrect: 0, prompt: 0 };

export const stepValuesSchema = z.object({
  correct: z.number().min(0),
  incorrect: z.number().min(0),
  prompt: z.number().min(0),
});

type StepValues = z.infer<typeof stepValuesSchema>;

const ButtonNumber = ({ number, disabled }: { number: number; disabled?: boolean }) => (
  <Stack
    sx={{
      bgcolor: !disabled ? "info.main" : "grey.200",
      height: 24,
      width: 24,
      borderRadius: 1,
      textAlign: "center",
      lineHeight: "24px",
    }}
  >
    <Typography variant="label">{number}</Typography>
  </Stack>
);

const AccuracyInput = ({
  value,
  stepValues,
  writeValue,
  status,
  disabled,
  compact,
}: {
  value: number;
  stepValues?: StepValues;
  writeValue: (n: number, s?: StepValues) => Promise<void>;
  status?: Status;
  disabled?: boolean;
  compact?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(toPercentString(value));
  const [inputSteps, setInputSteps] = useState(stepValues);

  const attemptWrite = (newValue: number, newStepValues: StepValues | undefined) =>
    writeValue(newValue, newStepValues).catch(() => {
      setInputValue(toPercentString(value));
      setInputSteps(stepValues);
    });

  useEffect(() => {
    setInputValue(toPercentString(value));
    setInputSteps(stepValues);
  }, [value, stepValues]);

  const onInputChange = (stringValue: string) => {
    const noPercentString = stringValue.replace(/%/g, "");
    setInputValue(`${noPercentString}%`);
    const numberValue = parseFloat(noPercentString);
    if (isNaN(numberValue) || numberValue < 1) return;
    setInputSteps(undefined);
    attemptWrite(numberValue / 100, undefined);
  };

  const incrementStep = (stepType: "correct" | "incorrect" | "prompt") => () => {
    const currentSteps = stepValues || DEFAULT_STEP_VALUES;
    const updatedSteps = { ...currentSteps, [stepType]: currentSteps[stepType] + 1 };
    setInputSteps(updatedSteps);

    const totalAttempts = updatedSteps.correct + updatedSteps.incorrect;
    const newValue = totalAttempts ? roundToDecimal(updatedSteps.correct / totalAttempts, 4) : 0;
    setInputValue(toPercentString(newValue));

    attemptWrite(newValue, updatedSteps);
  };

  const reset = () => {
    setInputValue("0%");
    const zeroSteps = { correct: 0, incorrect: 0, prompt: 0 };
    setInputSteps(zeroSteps);
    attemptWrite(0, zeroSteps);
  };
  const resetButton = (
    <Button startIcon={<ReplayIcon />} onClick={reset} disabled={disabled} size={compact ? "small" : undefined}>
      Reset
    </Button>
  );

  return (
    <Stack gap={compact ? 1 : 2} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <ObjectiveValueInput
          value={inputValue}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
          compact={compact}
          sx={{ width: 200 }}
        />
        {compact && resetButton}
      </Stack>
      <Stack direction="row" gap={compact ? 1 : 2} sx={{ maxWidth: "100%" }}>
        <Stack width={150} gap={1} alignItems="center">
          {!compact && <SmallValueInput value={inputSteps ? inputSteps.correct : "N/A"} disabled />}
          <Button
            variant="contained"
            fullWidth
            startIcon={
              compact && inputSteps ? <ButtonNumber number={inputSteps.correct} disabled={disabled} /> : <AddIcon />
            }
            onClick={incrementStep("correct")}
            disabled={disabled}
          >
            Correct
          </Button>
        </Stack>
        <Stack width={150} gap={1} alignItems="center">
          {!compact && <SmallValueInput value={inputSteps ? inputSteps.incorrect : "N/A"} disabled />}
          <Button
            variant="contained"
            fullWidth
            startIcon={
              compact && inputSteps ? (
                <ButtonNumber number={inputSteps.incorrect} disabled={disabled} />
              ) : (
                <RemoveIcon />
              )
            }
            onClick={incrementStep("incorrect")}
            disabled={disabled}
          >
            Incorrect
          </Button>
        </Stack>
        <Stack width={150} gap={1} alignItems="center">
          {!compact && <SmallValueInput value={inputSteps ? inputSteps.prompt : "N/A"} disabled />}
          <Button
            variant="contained"
            fullWidth
            startIcon={
              compact && inputSteps ? (
                <ButtonNumber number={inputSteps.prompt} disabled={disabled} />
              ) : (
                <AddCommentIcon />
              )
            }
            onClick={incrementStep("prompt")}
            disabled={disabled}
          >
            Prompt
          </Button>
        </Stack>
      </Stack>
      {!compact && resetButton}
      {status && !compact && <StatusIcon status={status} />}
    </Stack>
  );
};

export default AccuracyInput;
