import { useEffect, useRef, useState, useContext } from "react";
import { useLocalSessionId, useParticipantProperty } from "@daily-co/daily-react";
import { observer } from "mobx-react-lite";
import { io, Socket } from "socket.io-client";
import config from "@/config";
import { loggerContext, MeetingStoreContext } from "@/stores";
import { DailyParticipantData } from "@/utils/daily.utils";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("SpeechRecognition", loggerContext);

const SpeechRecognition = ({ meetingKey, participantKey }: { meetingKey: string; participantKey: string }) => {
  const socketRef = useRef<Socket | null>(null);
  const recognitionRef = useRef<any>(null);

  const meetingStore = useContext(MeetingStoreContext);
  const { isLiveCaptioningEnabled } = meetingStore;

  const localSessionId = useLocalSessionId();
  const [tracks, untypedUserData] = useParticipantProperty(localSessionId, ["tracks", "userData"]);
  const userData = untypedUserData as DailyParticipantData | undefined;
  const isAudioMuted = tracks?.audio?.state !== "playable";
  const [localTranscript, setLocalTranscript] = useState("");

  useEffect(() => {
    socketRef.current = io(config.nexusServerUrl, {
      query: { meetingRoomId: meetingKey },
    });

    socketRef.current.on("connect", () => {});

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [meetingKey]);

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const SpeechRecognitionApi = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current.onresult = null;
        recognitionRef.current.onerror = null;
      }

      const recognition = new SpeechRecognitionApi();
      recognition.continuous = true;
      recognition.lang = "en-US";
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;

      recognition.onresult = (event: any) => {
        logger.info("Speech recognition result event:", event);
        let continuousTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (!event.results[i].isFinal) {
            continuousTranscript += event.results[i][0].transcript;
          }
        }
        setLocalTranscript(continuousTranscript.slice(-100));
      };

      recognition.onerror = (event: any) => {
        if (event.error !== "no-speech") {
          logger.error("Speech recognition error", event, event.error);
        }
      };

      recognition.onend = () => {
        if (isLiveCaptioningEnabled && !isAudioMuted) {
          recognition.start();
        }
      };

      recognitionRef.current = recognition;

      if (isLiveCaptioningEnabled && !isAudioMuted) {
        recognition.start();
        logger.info("Speech recognition started", recognition);
      } else {
        recognition.stop();
        logger.info("Speech recognition stopped", recognition);
      }

      return () => {
        recognition.stop();
        logger.info("Speech recognition stopped", recognition);
      };
    } else {
      logger.warn("Speech recognition API not supported");
    }
  }, [isLiveCaptioningEnabled, isAudioMuted]);

  useEffect(() => {
    if (socketRef.current && userData && localTranscript) {
      logger.info("Sending meeting transcription", { meetingKey, participantKey, transcript: localTranscript });
      socketRef.current.emit("meetingTranscription", {
        meetingRoomId: meetingKey,
        participantId: userData.participantKey,
        transcript: localTranscript,
      });
    }
  }, [localTranscript, meetingKey, userData]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on(
        "meetingTranscription",
        ({ participantId, transcript }: { participantId: string; transcript: string }) => {
          if (participantId !== participantKey) {
            meetingStore.setProviderTranscript(transcript);
          }
        },
      );
    }
  }, [participantKey]);

  return null;
};

export default observer(SpeechRecognition);
