export const NEXUS_ERROR_CODE_KEY = "nexusErrorCode";

export const NexusErrorCode = {
  DuplicateStudentWrite: "DUPLICATE_STUDENT_WRITE",
  DuplicateUserEmailWrite: "DUPLICATE_USER_EMAIL_WRITE",
} as const;

export type NexusErrorCode = (typeof NexusErrorCode)[keyof typeof NexusErrorCode];

export const NEXUS_ERROR_CODES: NexusErrorCode[] = Object.values(NexusErrorCode);
