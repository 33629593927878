import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ArrowsPointingOutIcon, ArrowsPointingInIcon } from "@heroicons/react/20/solid";
import { IonIcon } from "@ionic/react";
import { observer } from "mobx-react-lite";
import Link from "@/components/common/elements/Link";
import ToolTip from "@/components/common/elements/ToolTip";
import SmileIcon from "@/icons/square-smile.svg";
import { MeetingStoreContext } from "@/stores";
import { classNames } from "@/utils";

const menuItemClasses =
  "flex flex-row block px-4 py-2 text-sm hover:bg-ocean-light hover:cursor-pointer text-ocean gap-2 items-center";

const WindowControlMenu = () => {
  const meetingStore = useContext(MeetingStoreContext);
  const { isClientSelfViewEnabled, isProviderSelfViewDisabled } = meetingStore;

  return (
    <Menu as="div" className="flex flex-row justify-center">
      <Menu.Button
        data-tooltip-id="window-control-button-tooltip"
        className="bg-white border text-primary hover:text-secondary h-10 w-10 rounded-full text-2xl cursor-pointer flex justify-center items-center border-primary border-1"
      >
        <IonIcon icon={SmileIcon} />
        <ToolTip place="top" id="window-control-button-tooltip">
          Window Controls
        </ToolTip>
      </Menu.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            meetingStore.rightSidebarType ? "right-80" : "right-1",
            "absolute bottom-[52px] z-10 w-52 origin-top-right rounded-md bg-white shadow-md ring-1 ring-ocean ring-opacity-0  focus:outline-none",
          )}
        >
          <Menu.Item>
            <Link
              className={menuItemClasses}
              onClick={() => {
                const isDisabled = !isProviderSelfViewDisabled;
                meetingStore.updateDisplayState(
                  { isProviderSelfViewDisabled: isDisabled },
                  isDisabled ? "providerSelfViewOff" : "providerSelfViewOn",
                  { skipSync: true },
                );
              }}
            >
              {isProviderSelfViewDisabled ? (
                <ArrowsPointingOutIcon className="h-5" />
              ) : (
                <ArrowsPointingInIcon className="h-5" />
              )}
              {isProviderSelfViewDisabled ? "Maximize my video" : "Minimize my video"}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link
              className={menuItemClasses}
              onClick={() => {
                const isEnabled = !isClientSelfViewEnabled;
                meetingStore.updateDisplayState(
                  { isClientSelfViewEnabled: isEnabled },
                  isEnabled ? "clientSelfViewOn" : "clientSelfViewOff",
                );
              }}
            >
              <IonIcon icon={SmileIcon} className="text-xl" />
              {isClientSelfViewEnabled ? "Disable client self view" : "Enable client self view"}
            </Link>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default observer(WindowControlMenu);
