import { ReactNode, useContext } from "react";
import { useLocation } from "react-router";
import { IonIcon } from "@ionic/react";
import { syncOutline, qrCodeOutline } from "ionicons/icons";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import Link from "@/components/common/elements/Link";
import PlaceholderContainer from "@/components/common/layout/PlaceholderContainer";
import config from "@/config";
import uTurnLeft from "@/icons/uturn-left.svg";
import { StoreContext, loggerContext } from "@/stores";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";
import ClientFeedbackSurvey from "../activity/survey/ClientFeedbackSurvey";
import { RightSidebar } from "./sidebar/MeetingSidebar";

const logger = initLogger("MeetingPlaceholder", loggerContext);

export const MeetingPlaceholderContainer = ({ children }: { children: ReactNode }) => (
  <PlaceholderContainer
    sidebar={
      <div className="flex flex-col overflow-hidden">
        <RightSidebar />
      </div>
    }
  >
    {children}
  </PlaceholderContainer>
);

export type FinishedActionProps = {
  text: string;
  fn?: () => void;
  link?: string;
  icon?: string;
};

const FinishedAction = ({ text, fn, link, icon }: FinishedActionProps) => {
  const button = (
    <button className="bg-primary p-2 rounded-md text-white flex justify-center items-center" onClick={fn}>
      <IonIcon icon={icon} className="text-xl px-2" /> <span className="pr-2">{text}</span>
    </button>
  );
  if (!link) return button;
  return (
    <Link href={link} newTab={false}>
      {button}
    </Link>
  );
};

export const MeetingFinished = ({
  userId,
  appointment,
  isKicked,
  reconnect,
}: {
  userId: string;
  appointment?: ExtendedAppointment;
  reconnect: () => void;
  isKicked?: boolean;
}) => {
  const {
    meetingStore: { feedbackFormTemplateId },
    participantStore: { localFeedbackSurveyStatus },
    userStore: { isClient },
  } = useContext(StoreContext);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const redirect = useEventRedirect(logger);

  const message = isKicked ? "You have been kicked from the meeting by the provider" : "Thanks for joining 🎉";

  const waitingRoomUrl = appointment ? `/waiting-room/appointment/${appointment.appointmentId}/v2` : "";
  const rejoinActionProps: FinishedActionProps =
    isClient && waitingRoomUrl
      ? {
          text: "Rejoin Waiting Room",
          fn: () => redirect(waitingRoomUrl, `post-meeting nav to waiting room`),
          icon: syncOutline,
        }
      : {
          text: "Rejoin Meeting",
          fn: reconnect,
          icon: syncOutline,
        };

  let goBackActionProps: FinishedActionProps = {
    text: "Back to Lobby",
    fn: () => redirect("/lobby", "post-meeting nav to lobby"),
    icon: uTurnLeft,
  };
  if (queryParams.get("healthieAppointmentId"))
    goBackActionProps = {
      text: "Back to Parallel Homepage",
      link: `${config.healthieUrl}/appointments`,
      icon: uTurnLeft,
    };

  const actionProps = [rejoinActionProps, goBackActionProps];

  if (isClient) {
    actionProps.push({
      text: "Enter Session Code",
      fn: () => redirect("/join", "post-meeting nav to session code", { clearParams: true }),
      icon: qrCodeOutline,
    });
  }

  const displayFeedbackFormTemplateId = isClient && !isKicked && !localFeedbackSurveyStatus && feedbackFormTemplateId;

  return (
    <MeetingPlaceholderContainer>
      <p role="heading" className="text-3xl">
        {message}
      </p>
      <div className="flex flex-row gap-4">
        {actionProps.map((a, i) => (
          <FinishedAction {...a} key={i} />
        ))}
      </div>
      {!appointment && displayFeedbackFormTemplateId && (
        <div className="w-1/2 h-2/3 pt-4">
          <div className="w-full p-6 bg-inherit">
            <ClientFeedbackSurvey userId={userId} feedbackFormTemplateId={displayFeedbackFormTemplateId} />
          </div>
        </div>
      )}
    </MeetingPlaceholderContainer>
  );
};
