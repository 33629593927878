import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable, isHydrated } from "mobx-persist-store";
import { v4 as uuidv4 } from "uuid";
import { AppointmentCodeSignIn, AuthorizedUser } from "@parallel/vertex/types/auth.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { getInitials } from "@parallel/vertex/util/string.util";
import { AuthAPI } from "@/api/auth.api";
import config from "@/config";
import { TelehealthLogger } from "@/utils/logging.utils";

export enum UserType {
  Staff,
  Client,
  Observer,
  Unknown,
}

type Authorization =
  | {
      type: "user-sign-in";
      token: string;
      sessionId: string;
    }
  | {
      type: "appointment-code";
      token: string;
      sessionId: string;
      appointmentId: string;
      userId: string;
    };

export class UserStore {
  user?: ExtendedUser = undefined;
  auth?: Authorization;

  appThreadId: string = uuidv4().split("-")[0];

  shouldPersist: boolean;

  signInAttemptTimestamps: string[] = [];

  authApi: AuthAPI = {} as AuthAPI;

  constructor(
    public logger: TelehealthLogger,
    shouldPersist = true,
  ) {
    this.shouldPersist = shouldPersist;

    makeAutoObservable(this);

    shouldPersist &&
      makePersistable(this, {
        name: "UserStore",
        properties: ["auth", "signInAttemptTimestamps"],
        storage: window.localStorage,
      });
  }

  setActiveUser(user: ExtendedUser) {
    this.user = user;
  }

  userSignedIn(user: AuthorizedUser) {
    this.setActiveUser(user);
    this.auth = { type: "user-sign-in", token: user.accessToken, sessionId: user.sessionId };
  }

  setAuthorizedAppointmentCode(signIn: AppointmentCodeSignIn) {
    this.auth = {
      type: "appointment-code",
      token: signIn.accessToken,
      sessionId: signIn.sessionId,
      appointmentId: signIn.appointment.appointmentId,
      userId: signIn.user.userId,
    };
  }

  async restoreSignIn() {
    // if most recent auth was done via appointment code, simply fetch the user w/o affecting cookies or auth state
    if (this.auth?.type === "appointment-code") {
      const codeUser = await this.authApi.getCurrentUser();
      this.setActiveUser(codeUser);
      return codeUser;
    } else {
      const restoreUser = await this.authApi.restoreSignIn();
      this.userSignedIn(restoreUser);
      return restoreUser;
    }
  }

  async setAppointmentCodeUser(user: ExtendedUser, shortCode: string) {
    this.user = user;

    // if `user` is not already authorized via appointment code, fetch + set a new authorization
    if (this.auth?.type === "appointment-code" && this.auth.userId === user.userId) return;

    await this.authApi
      .postAppointmentCodeSignIn(shortCode)
      .then(r => this.setAuthorizedAppointmentCode(r))
      .catch(e => this.logger.warn("error updating appointment code user", e));
  }

  setWatcherUser(signIn: AppointmentCodeSignIn) {
    this.auth = { type: "user-sign-in", token: signIn.accessToken, sessionId: signIn.sessionId };
  }

  async signOut() {
    await this.authApi.postSignOut().catch(e => this.logger.warn("error signing out", undefined, e));
    runInAction(() => {
      this.user = undefined;
      this.auth = undefined;
    });
  }

  signInAttempt() {
    const now = DateTime.utc();
    const recentFailures = this.signInAttemptTimestamps.filter(
      d => now.diff(DateTime.fromISO(d), "minutes").minutes < config.signInThrottle.timeoutMinutes,
    );
    this.signInAttemptTimestamps = [...recentFailures, now.toISO()];
    return this.signInAttemptTimestamps.length;
  }

  get signedInUserId() {
    return this.auth?.type === "user-sign-in" ? this.user?.userId : undefined;
  }

  get userId(): string | undefined {
    return this.user?.userId;
  }
  get firstName(): string | undefined {
    return this.user?.firstName;
  }
  get lastName(): string | undefined {
    return this.user?.lastName;
  }
  get email(): string | undefined {
    return this.user?.email || undefined;
  }
  get phoneNumber(): string | undefined {
    return this.user?.phoneNumber || undefined;
  }
  get fullName() {
    if (!this.user) return undefined;
    return `${this.firstName} ${this.lastName}`;
  }
  get isStaff() {
    return this.user?.userType === "PROVIDER";
  }
  get isClient() {
    return this.user?.userType === "STUDENT";
  }
  get initials() {
    return this.user && getInitials(this.user);
  }
  get sessionId() {
    return this.auth?.sessionId;
  }
  get sessionKey() {
    return this.sessionId || this.appThreadId;
  }
  get isHydrated() {
    return !this.shouldPersist || isHydrated(this);
  }
}
