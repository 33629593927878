import { padStart } from "lodash";

export const numberExists = (n?: number | null): n is number => n === 0 || !!n;

export const roundToDecimal = (n: number, decimals: number) => {
  const decimalFactor = Math.pow(10, decimals);
  return Math.round(n * decimalFactor) / decimalFactor;
};

export const toPercentString = (n: number) => `${roundToDecimal(n * 100, 2)}%`;

export const secondsToString = (n: number) => `${Math.floor(n / 60)}:${padStart((n % 60).toString(), 2, "0")}`;
