import { TextFieldProps } from "@mui/material";
import { omit } from "lodash";
import { MonospaceValueInput } from "../../shared/input/value.input";

const ObjectiveValueInput = (props: TextFieldProps & { compact?: boolean }) => (
  <MonospaceValueInput
    {...omit(props, "compact")}
    sx={{
      width: 200,
      "& .MuiInputBase-root": {
        height: props.compact ? 30 : undefined,
        fontSize: props.compact ? 16 : 32,
      },
      ...props.sx,
    }}
  />
);

export default ObjectiveValueInput;
