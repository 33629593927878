import { useState } from "react";
import Stack from "@mui/material/Stack";
import AutoSaveTextField from "../../shared/input/AutoSaveTextField";
import { Status, StatusIcon } from "../../shared/input/status.input";

const NoteInput = ({
  objectiveId,
  value,
  writeValue,
  onValidSave,
  status,
  disabled,
  compact,
}: {
  objectiveId: string;
  value: string;
  writeValue: (s: string) => Promise<unknown>;
  onValidSave: (s: string) => void;
  status?: Status;
  disabled?: boolean;
  compact?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(value);

  const attemptWrite = (newValue: string) => {
    onValidSave(newValue);
    return writeValue(newValue).catch(() => {
      setInputValue(value);
      onValidSave(value);
    });
  };

  return (
    <Stack gap={1} alignItems="center">
      <AutoSaveTextField
        label="Objective Note"
        fullWidth
        multiline
        rows={compact ? 3 : 12}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onSave={attemptWrite}
        saveKey={objectiveId}
        disabled={disabled}
        key={objectiveId}
      />
      {status && !compact && <StatusIcon status={status} />}
    </Stack>
  );
};

export default NoteInput;
