import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { GameConfig, GameState } from "@parallel/vertex/types/game.types";
import { getSequence } from "@parallel/vertex/util/collection.util";
import Button from "@/components/common/elements/Button";
import Select from "@/components/common/elements/Select";
import { ActivityStoreContext } from "@/stores";

const DEFAULT_SPINNER_SLICES = 6;
const MAX_SPINNER_SLICES = 12;

const SingleGameOption = ({
  game,
  selectGame,
}: {
  game: GameConfig;
  selectGame: (initialState: GameState) => void;
}) => {
  const { defaultPieces = [] } = game;
  const playerCountInterval = game.players
    ? {
        min: game.players.min,
        max: game.players.pieces.length,
      }
    : undefined;
  const [playerCount, setPlayerCount] = useState(playerCountInterval?.min);

  const [spinnerSliceCount, setSpinnerSliceCount] = useState(DEFAULT_SPINNER_SLICES);

  const playerPieces = (game.players?.pieces || []).slice(0, playerCount);
  const onSelect = () =>
    selectGame({
      gameId: game.id,
      spinner: { sliceCount: spinnerSliceCount, result: 1, spinCount: 0 },
      pieces: [...defaultPieces, ...playerPieces],
    });

  return (
    <div className="flex flex-col gap-2" role="menuitem" aria-label={game.id}>
      <span className="text-xl">{game.name}</span>
      {playerCountInterval && (
        <Select
          label="Player Count"
          showLabel={true}
          value={playerCount?.toString()}
          options={getSequence(playerCountInterval.min, playerCountInterval.max).map(n => ({ value: n.toString() }))}
          onChange={v => setPlayerCount(parseInt(v))}
        />
      )}
      {game.spinnerArea && (
        <Select
          label="Spinner Slice Count"
          showLabel={true}
          value={spinnerSliceCount.toString()}
          options={getSequence(3, MAX_SPINNER_SLICES).map(n => ({
            value: n.toString(),
          }))}
          onChange={v => setSpinnerSliceCount(parseInt(v))}
        />
      )}
      <Button text="Load" onClick={onSelect} />
    </div>
  );
};

const _GameMenuOptions = () => {
  const activityStore = useContext(ActivityStoreContext);
  const { gameMetadata } = activityStore;
  return (
    <div className="w-full h-full flex flex-col gap-6">
      {gameMetadata.map(g => (
        <SingleGameOption game={g} selectGame={initialState => activityStore.selectGame(initialState)} key={g.id} />
      ))}
    </div>
  );
};

export const GameMenuOptions = observer(_GameMenuOptions);

const GameMenu = () => {
  const activityStore = useContext(ActivityStoreContext);
  const { currGameMetadata } = activityStore;
  return (
    <div className="h-full overflow-y-auto flex flex-col">
      {currGameMetadata && (
        <div className="p-4 border-b border-slate-300 bg-light" role="region" aria-label="selected-game">
          <p className="text-slate-600 text-sm">Now Showing:</p>
          <p className="text-primary text-lg mb-1 font-semibold">{currGameMetadata.name}</p>
          <button
            onClick={() => activityStore.hideGame()}
            className="my-1.5 py-0.5 px-3 text-md bg-primary text-zinc-50 rounded-md hover:bg-navy"
          >
            Stop Sharing
          </button>
        </div>
      )}
      <div className="w-full flex-1 p-4">
        <GameMenuOptions />
      </div>
    </div>
  );
};

export default observer(GameMenu);
