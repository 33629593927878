import { alpha, createTheme, SxProps } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    softred: Palette["primary"];
    bordergrey: Palette["primary"];
    highlight: Palette["primary"];
    surface: Palette["primary"];
  }

  interface PaletteOptions {
    softred?: PaletteOptions["primary"];
    lightgrey?: PaletteOptions["primary"];
    highlight?: PaletteOptions["primary"];
    surface?: PaletteOptions["primary"];
  }

  interface TypographyVariants {
    label: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

const baseTheme = createTheme({
  palette: {
    primary: {
      light: alpha("#326DDB", 0.4),
      main: "#326DDB",
    },
    success: {
      main: "#6BCA7D",
    },
    info: {
      main: "#5B7EC8",
    },
    error: {
      main: "#D32F2F",
    },
    warning: {
      main: "#FF9F49",
    },
    text: {
      primary: "#212121",
      secondary: "#5C5C5C",
      disabled: "#707070",
    },
    background: {
      default: "#FFFFFF",
    },
    tonalOffset: {
      light: 0.4,
      dark: 0.2,
    },
  },
  typography: {
    fontFamily: "Matter, Sans-serif",
    button: { textTransform: "none" },
    h1: {
      letterSpacing: "0.15px",
      fontSize: "2rem", // 32px
      fontWeight: 400, // regular
    },
    h2: {
      letterSpacing: "0.15px",
      fontSize: "1.5rem", // 24px
      fontWeight: 400, // regular
    },
    h3: {
      letterSpacing: "0.15px",
      fontSize: "1rem", // 20px
      fontWeight: 500, // medium
    },
    h4: {
      letterSpacing: "0.15px",
      fontSize: "0.875rem", // 14px
      fontWeight: 500, // medium
    },
    subtitle1: {
      letterSpacing: "0.15px",
      fontSize: "1rem", // 16px
      fontWeight: 500, // medium
    },
    subtitle2: {
      letterSpacing: "0.15px",
      fontSize: "0.875rem", // 14px
      fontWeight: 500, // medium
    },
    body1: {
      letterSpacing: "0.15px",
      fontSize: "1rem", // 16px
      fontWeight: 400, // regular
    },
    body2: {
      letterSpacing: "0.15px",
      fontSize: "0.875rem", // 14px
      fontWeight: 400, // regular
    },
    label: {
      letterSpacing: "0.15px",
      fontSize: "0.75rem", // 12px
      fontWeight: 400, // regular
    },
  },
  components: {
    MuiStack: {
      styleOverrides: {
        root: {
          WebkitPrintColorAdjust: "exact",
        },
      },
    },
  },
});

export const defaultTheme = createTheme(baseTheme, {
  palette: {
    softred: baseTheme.palette.augmentColor({
      color: {
        main: "#CA6D6D",
      },
      name: "softred",
    }),
    bordergrey: baseTheme.palette.augmentColor({
      color: {
        main: "#9897A9",
      },
      name: "bordergrey",
    }),
    surface: baseTheme.palette.augmentColor({
      color: {
        light: "#FAFAFA",
        main: alpha("#326DDB", 0.08),
        dark: "#294F8E",
      },
      name: "surface",
    }),
    highlight: baseTheme.palette.augmentColor({
      color: {
        main: "#edf7fe",
      },
      name: "highlight",
    }),
  },
});

export const percentString = (proportion: number) => `${Math.round(proportion * 10000) / 100}%`;

export const displayInputFieldStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
  },
  "& .MuiChip-root.Mui-disabled": {
    opacity: 1,
    "& .MuiChip-deleteIcon": {
      display: "none",
    },
  },
};

export const lineClampStyles = (lines: number): SxProps => ({
  display: "-webkit-box",
  WebkitLineClamp: lines,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
