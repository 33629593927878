import { omit } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import {
  AssessmentTestSelection,
  AssessmentZoomSelection,
} from "@parallel/vertex/types/assessment/assessment.testing.types";
import { GameConfig } from "@parallel/vertex/types/game.types";
import { GameState } from "@parallel/vertex/types/game.types";
import { ActivityState } from "@parallel/vertex/types/meeting.types";
import { Position } from "@parallel/vertex/types/shared.types";
import { CobrowsingAPI } from "@/api/cobrowsing.api";
import { MeetingAPI } from "@/api/meeting.api";
import { UserStore } from "@/stores/user.store";
import { TelehealthLogger } from "@/utils/logging.utils";
import { MeetingStore } from "./meeting.store";

type ActivityWriteOperation =
  | "showWhiteboard"
  | "hideWhiteboard"
  | "showTest"
  | "hideTest"
  | "showTestZoom"
  | "hideTestZoom"
  | "showGame"
  | "hideGame"
  | "updateGame"
  | "showCobrowsing"
  | "hideCobrowsing";

export class ActivityStore {
  triggerRemoteSync?: (operation: ActivityWriteOperation, logParams?: any) => unknown = undefined;

  currActivity?: ActivityState = undefined;

  gameMetadata: GameConfig[] = [];
  selectedGameInitialState?: GameState = undefined;

  constructor(
    private meetingStore: MeetingStore,
    private userStore: UserStore,
    private cobrowsingApi: CobrowsingAPI,
    private meetingApi: MeetingAPI,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  get currActivityType() {
    return this.currActivity?.type;
  }

  get currAssessmentTest() {
    if (this.currActivity?.type !== "assessment") return;
    return this.currActivity;
  }

  get currGame() {
    if (this.currActivity?.type !== "game") return;
    return this.currActivity;
  }

  get currGameMetadata() {
    const currGame = this.currGame;
    return currGame ? this.gameMetadata.find(g => g.id === currGame.gameId) : undefined;
  }

  async sync(meetingKey: string, { operation = "unknown", logParams, syncId }: any) {
    const { activity } = await this.meetingApi.getMeetingState(meetingKey);
    runInAction(() => (this.currActivity = activity || undefined));

    this.logger.postEvent("StateSync", `successfully received ${operation} activity state sync`, {
      operation,
      logParams,
      activity,
      syncId,
    });
  }

  toggleWhiteboard = (isEnabled: boolean) =>
    this.updateActivity(isEnabled ? { type: "whiteboard" } : null, isEnabled ? "showWhiteboard" : "hideWhiteboard");

  setAssessmentStimulus = (assessment: AssessmentTestSelection | null) =>
    assessment
      ? this.updateActivity({ type: "assessment", ...assessment }, "showTest", { testId: assessment.id })
      : this.updateActivity(null, "hideTest");

  updateAssessmentZoom = (zoom: AssessmentZoomSelection | undefined) => {
    if (!this.currAssessmentTest) return;
    return this.updateActivity({ ...this.currAssessmentTest, zoom }, zoom ? "showTestZoom" : "hideTestZoom");
  };

  selectGame = (game: GameState) => {
    this.selectedGameInitialState = { ...game };
    return this.updateActivity({ type: "game", ...game }, "showGame", { gameId: game.gameId });
  };

  updateSelectedGame = (gameUpdate: Partial<GameState>, updateType: string) => {
    if (!this.currGame) return;
    this.updateActivity({ ...this.currGame, ...gameUpdate }, "updateGame", { updateType });
  };

  updatePiecePosition = (updatePieceId: string, newPosition: Position) => {
    if (!this.currGame) return;
    const pieces = this.currGame.pieces.map(piece =>
      piece.id === updatePieceId
        ? {
            ...piece,
            relativeArea: { ...piece.relativeArea, offset: newPosition },
            lastMovedBy: this.meetingStore.connectedParticipantKey,
          }
        : piece,
    );
    this.updateSelectedGame({ pieces }, "updatePiecePosition");
  };

  resetGame = () => {
    if (!this.selectedGameInitialState) return;
    return this.updateSelectedGame(omit(this.selectedGameInitialState, "spinner"), "resetGame");
  };

  hideGame = () => this.updateActivity(null, "hideGame");

  toggleCobrowsing = (isEnabled: boolean) => {
    if (isEnabled) {
      this.prepareCobrowsingSession().then(res => {
        if (res) {
          this.updateActivity(
            isEnabled
              ? {
                  type: "cobrowsing",
                  cobrowsingLeaderLink: res.leaderLink,
                  cobrowsingFollowerLink: res.followerLink,
                  sessionId: res.sessionId,
                }
              : null,
            isEnabled ? "showCobrowsing" : "hideCobrowsing",
          );
        }
      });
    } else {
      if (this.currActivity?.type === "cobrowsing") {
        this.endCobrowsingSession(this.currActivity.sessionId);
      }
    }
  };

  async endCobrowsingSession(sessionId: string | undefined) {
    if (!sessionId) return;
    return this.cobrowsingApi.endCobrowsingSession(sessionId).then(_ => {
      this.updateActivity(null, "hideCobrowsing");
    });
  }

  async prepareCobrowsingSession() {
    if (!this.meetingStore.appointment || !this.userStore.userId) return;
    return this.cobrowsingApi
      .prepareCobrowsingSession(this.meetingStore.appointment?.appointmentId, this.userStore.userId)
      .then(res => res)
      .catch(e => {
        this.logger.postEvent("Warning", "error preparing cobrowsing session", {
          error: e.message,
        });
      });
  }

  private updateActivity = async (
    activity: ActivityState | null,
    operation: ActivityWriteOperation,
    logParams?: any,
  ) => {
    try {
      const { connectedMeetingKey: meetingKey } = this.meetingStore;
      if (!meetingKey) return;

      this.currActivity = activity || undefined;

      const { activity: updatedRemote } = await this.meetingApi.updateMeetingActivity(meetingKey, activity);

      if (!this.triggerRemoteSync) throw new Error("activity state update remote trigger not set");
      this.triggerRemoteSync(operation, logParams);

      this.logger.postEvent("StateSync", `successfully triggered ${operation} activity state sync`, {
        operation,
        logParams,
        updatedLocal: activity,
        updatedRemote,
      });
    } catch (e: any) {
      this.logger.postEvent("Warning", `error triggering ${operation} activity state sync`, {
        operation,
        logParams,
        error: e.message,
        request: activity,
      });
    }
  };
}
