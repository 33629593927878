import { ReactNode, useContext } from "react";
import { IonIcon } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { StoreContext } from "@/stores";
import { classNames } from "@/utils";
import AssessmentsMenu from "./assessment/AssessmentsMenu";
import GameMenu from "./game/GameMenu";

export type ActivitySubmenuType = "assessment" | "game" | "cobrowsing";

type ActivityButtonProps = { title: string; icon: ReactNode; onClick?: () => void; isSelected?: boolean };

const ActivityButton = ({ title, icon, onClick, isSelected }: ActivityButtonProps) => {
  const colorClassName = isSelected ? "text-primary border-primary" : "text-slate-600 border-slace-200";
  return (
    <div
      className={classNames("w-full px-4", colorClassName, "hover:bg-light hover:text-primary hover:border-primary")}
    >
      <div
        className="w-full px-4 py-2 flex flex-row items-center gap-3 text-xl cursor-pointer border-l-2 border-inherit"
        onClick={onClick}
      >
        {icon}
        <span>{title}</span>
      </div>
    </div>
  );
};

const ActivitySubmenu = ({ submenu }: { submenu: ActivitySubmenuType }) => {
  switch (submenu) {
    case "assessment":
      return <AssessmentsMenu />;
    case "game":
      return <GameMenu />;
  }
};

const ActivityMenu = ({
  submenu,
  setSubmenu,
}: {
  submenu?: ActivitySubmenuType;
  setSubmenu: (a?: ActivitySubmenuType) => void;
}) => {
  const {
    activityStore: { currActivityType, toggleCobrowsing, toggleWhiteboard },
    meetingStore: { isTestingSession, appointmentFlags },
  } = useContext(StoreContext);
  const enableCobrowsing = appointmentFlags?.enableCobrowsing;

  if (submenu) return <ActivitySubmenu submenu={submenu} />;

  return (
    <div className="py-4">
      <ActivityButton
        title="Games"
        icon={<IonIcon icon={Icons.dice} />}
        onClick={() => setSubmenu("game")}
        isSelected={currActivityType === "game"}
      />
      <ActivityButton
        title="Whiteboard"
        icon={<IonIcon icon={Icons.pencil} />}
        onClick={() => toggleWhiteboard(currActivityType !== "whiteboard")}
        isSelected={currActivityType === "whiteboard"}
      />
      {isTestingSession && (
        <ActivityButton
          title="Assessments"
          icon={<IonIcon icon={Icons.book} />}
          onClick={() => setSubmenu("assessment")}
          isSelected={currActivityType === "assessment"}
        />
      )}
      {enableCobrowsing && (
        <ActivityButton
          title="Cobrowsing"
          icon={<IonIcon icon={Icons.powerSharp} />}
          onClick={() => toggleCobrowsing(currActivityType !== "cobrowsing")}
          isSelected={currActivityType === "cobrowsing"}
        />
      )}
    </div>
  );
};

export default observer(ActivityMenu);
