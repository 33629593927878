import { ReactNode, useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMountEffect } from "@react-hookz/web";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import GlobalFailureModal from "@/components/common/modal/GlobalFailureModal";
import AppointmentMeetingScreen from "@/screens/AppointmentMeetingScreen";
import AppointmentMeetingWatchScreen from "@/screens/AppointmentMeetingWatchScreen";
import AppointmentWaitingRoomScreen from "@/screens/AppointmentWaitingRoomScreen";
import CodeScreen from "@/screens/CodeScreen";
import ConnectionTestScreen from "@/screens/ConnectionTestScreen";
import LoadingScreen from "@/screens/LoadingScreen";
import LobbyScreen from "@/screens/LobbyScreen";
import LoginScreen from "@/screens/LoginScreen";
import MeetingRoomScreen from "@/screens/MeetingRoomScreen";
import MeetingRoomWatchScreen from "@/screens/MeetingRoomWatchScreen";
import { StoreContext, UserStoreContext, loggerContext, userStore } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

const logger = initLogger("AppRoot", loggerContext);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Page = ({ title, children }: { title: string; children: ReactNode }) => {
  useEffect(() => {
    const pageTitle = `${title} - Parallel`;
    document.title = pageTitle;
    const page = {
      url: window.location,
      title,
    };
    logger.postEvent("PageLoad", `loaded page ${title}`, page);
  }, [title]);
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
};

const LoginPage = () => {
  return (
    <Page title="Login">
      <LoginScreen />
    </Page>
  );
};

const _MainContentPage = ({ title, screen, skipAuth }: { title: string; screen: ReactNode; skipAuth?: boolean }) => {
  const { userId } = useContext(UserStoreContext);

  const [isRestoringSignIn, setIsRestoringSignIn] = useState(!userId);
  useMountEffect(() => {
    if (isRestoringSignIn) {
      userStore
        .restoreSignIn()
        .catch(e => logger.operationError("restoreSignIn", e))
        .finally(() => setIsRestoringSignIn(false));
    }
  });
  if (isRestoringSignIn) return <LoadingScreen />;
  if (!userId && !skipAuth) return <LoginScreen isPreAuth={true} />;

  return (
    <Page title={title}>
      {screen}
      <GlobalFailureModal />
    </Page>
  );
};

const MainContentPage = observer(_MainContentPage);

function App() {
  const {
    userStore: { user, sessionId, appThreadId },
  } = useContext(StoreContext);

  useEffect(() => {
    Sentry.setUser({
      id: user?.userId,
      sessionId,
      appThreadId,
    });
  }, [user, sessionId, appThreadId]);

  return (
    <SentryRoutes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/join" element={<MainContentPage title="Join via Code" screen={<CodeScreen />} skipAuth={true} />} />
      <Route path="/lobby" element={<MainContentPage title="Lobby" screen={<LobbyScreen />} skipAuth={true} />} />
      <Route
        path="/s/:shortCode"
        element={<MainContentPage title="Lobby" screen={<LobbyScreen />} skipAuth={true} />}
      />

      <Route
        path="/waiting-room/appointment/:appointmentId/v2"
        element={<MainContentPage title="Waiting Room" screen={<AppointmentWaitingRoomScreen />} />}
      />
      <Route
        path="/meeting/appointment/:appointmentId/v2"
        element={<MainContentPage title="Meeting" screen={<AppointmentMeetingScreen />} />}
      />
      <Route
        path="/meeting/appointment/:appointmentCode/v2/watch"
        element={<MainContentPage title="Meeting Watch" screen={<AppointmentMeetingWatchScreen />} skipAuth={true} />}
      />
      <Route
        path="/meeting/room/:roomId"
        element={<MainContentPage title="Meeting Room" screen={<MeetingRoomScreen />} />}
      />
      <Route
        path="/meeting/room/:roomId/watch"
        element={<MainContentPage title="Meeting Room Watch" screen={<MeetingRoomWatchScreen />} skipAuth={true} />}
      />
      <Route
        path="/connection-test"
        element={<MainContentPage title="Test Connection" screen={<ConnectionTestScreen />} />}
      />

      {/* fallback redirection */}
      <Route path="*" element={<Navigate to="/login" />} />
    </SentryRoutes>
  );
}

export default observer(App);
