import { ReactElement, useContext, useEffect, useState } from "react";
import { useWaitingParticipants } from "@daily-co/daily-react";
import * as Icons from "ionicons/icons";
import { observer } from "mobx-react-lite";
import HeaderUserBadge from "@/components/common/elements/HeaderUserBadge";
import Header, { IonIconHeaderButton } from "@/components/common/layout/Header";
import { BaseHeaderLayout } from "@/components/common/layout/HeaderLayout";
import config from "@/config";
import PhotoIcon from "@/icons/photo-icon.svg";
import PeoplePlusIcon from "@/icons/plus-icon-with-people.svg";
import RecordStartCameraIcon from "@/icons/record-start-camera.svg";
import RecordStopCameraIcon from "@/icons/record-stop-camera.svg";
import { MeetingStoreContext, loggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";
import { takeScreenshot } from "@/utils/screenshot";

const logger = initLogger("Meeting", loggerContext);

const _MeetingHeaderControls = () => {
  const {
    alertStore,
    meetingStore,
    userStore: { isStaff },
  } = useContext(StoreContext);
  const isSessionRecording = !!meetingStore.inProgressRecording;

  const { waitingParticipants: waitingDailyParticipants } = useWaitingParticipants();

  const [prevWaitingCount, setPrevWaitingCount] = useState(0);
  const nextWaitingCount = waitingDailyParticipants.length;
  useEffect(() => {
    if (!isStaff) return;
    if (nextWaitingCount > prevWaitingCount)
      new Audio(config.alertChimeAudioFileUrl)
        .play()
        .catch(e => logger.operationError("playAlertChime", e, { nextWaitingCount, prevWaitingCount }, true));
    setPrevWaitingCount(nextWaitingCount);
  }, [nextWaitingCount]);

  const handleScreenshotButton = () =>
    takeScreenshot().catch(e => {
      logger.error("unexpected screenshare error", undefined, e);
      alertStore.push("Error capturing screenshot", { resolution: "retry" });
    });

  return (
    <>
      {isStaff && (
        <>
          {meetingStore.appointmentFlags?.canRecordSession && (
            <IonIconHeaderButton
              color={isSessionRecording ? "red" : "blue"}
              icon={isSessionRecording ? RecordStopCameraIcon : RecordStartCameraIcon}
              label={"Session Recording"}
              onClick={() => meetingStore.toggleSessionRecordingModal()}
            />
          )}
          <IonIconHeaderButton icon={PhotoIcon} label={"Take Screenshot"} onClick={() => handleScreenshotButton()} />
          <IonIconHeaderButton
            icon={PeoplePlusIcon}
            label={"Open Participant Manager"}
            onClick={() => meetingStore.toggleRightSideBar("participants")}
            badgeNumber={nextWaitingCount}
          />
        </>
      )}
      <IonIconHeaderButton
        icon={Icons.cog}
        label={"Open Settings"}
        onClick={() => meetingStore.toggleRightSideBar("settings")}
      />
      <IonIconHeaderButton
        icon={Icons.helpCircleOutline}
        label={"Help"}
        onClick={() => meetingStore.toggleRightSideBar("help")}
        placeTooltip="bottom"
      />
    </>
  );
};

const MeetingHeaderControls = observer(_MeetingHeaderControls);

export const HeaderBadge = ({
  leadText,
  mainText,
  readOnly,
}: {
  leadText: string;
  mainText: string;
  readOnly?: boolean;
}) => {
  const classes = `flex flex-col justify-center text-ocean ${!readOnly && "px-4 bg-neutral rounded-md border border-gray-300"}`;
  return (
    <div className={classes}>
      <span className="flex flex-row text-xs">{leadText}</span>
      <span className="text-sm">{mainText}</span>
    </div>
  );
};

const _AppointmentMeetingLayout = ({ children }: { children: ReactElement }) => {
  const { otherParticipantString } = useContext(MeetingStoreContext);
  const badges = (
    <>
      <HeaderUserBadge isMeeting={true} />
      {otherParticipantString && <HeaderBadge leadText="Meeting With" mainText={otherParticipantString} readOnly />}
    </>
  );
  const header = <Header badges={badges} controls={<MeetingHeaderControls />} />;
  return <BaseHeaderLayout header={header}>{children}</BaseHeaderLayout>;
};

export const AppointmentMeetingLayout = observer(_AppointmentMeetingLayout);

export const MeetingRoomLayout = ({ children, roomId }: { children: ReactElement; roomId?: string }) => {
  const badges = (
    <>
      <HeaderUserBadge isMeeting={true} />
      {roomId && <HeaderBadge leadText="Room ID" mainText={roomId} />}
    </>
  );
  const header = <Header badges={badges} controls={<MeetingHeaderControls />} />;
  return <BaseHeaderLayout header={header}>{children}</BaseHeaderLayout>;
};
