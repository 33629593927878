import { ReportVertical } from "../enums/report.enums";
import { ParallelEnv, isProd } from "./env.config";

type ReportReviewerConfig = { name: string; userId: string; isReportCreator?: boolean };

export type NoOverrideStaticConfig = {
  signInThrottle: { attempts: number; timeoutMinutes: number };
  reportReviewers: Partial<Record<ReportVertical, ReportReviewerConfig[]>>;
  oauthClientId: string;
};

export type NoOverrideGlobalStaticConfig = {
  nexusPort: number;
  supportLines: { email: string; emailHours: string; phone: string; phoneHours: string };
  testingSessionAppointmentTypeId: string;
  progressAppointmentTypeIds: string[];
};

const ENV_STATIC_CONFIG_KEYS = [
  "ablespaceUrl",
  "pathwayClientUrl",
  "launchDarklyClientId",
  "nexusServerUrl",
  "telehealthUrl",
  "orgUrl",
] as const;
type EnvStaticConfigKey = (typeof ENV_STATIC_CONFIG_KEYS)[number];
type EnvStaticConfig = {
  [key in EnvStaticConfigKey]: string;
};

const GLOBAL_STATIC_CONFIG_KEYS = [
  "staticAssetsUrl",
  "streamChatKey",
  "logHttpRequests",
  "logHttpResponses",
  "reportWriterHttpTarget",
] as const;
type GlobalStaticConfigKey = (typeof GLOBAL_STATIC_CONFIG_KEYS)[number];
type GlobalStaticConfig = {
  [key in GlobalStaticConfigKey]: string;
} & NoOverrideGlobalStaticConfig;

export const STATIC_CONFIG_KEYS = [...ENV_STATIC_CONFIG_KEYS, ...GLOBAL_STATIC_CONFIG_KEYS, "nexusPort"] as const;
export type StaticConfig = EnvStaticConfig & GlobalStaticConfig;

const local: Partial<StaticConfig & NoOverrideStaticConfig> = {
  nexusServerUrl: "http://localhost:8080",
  pathwayClientUrl: "http://localhost:3000",
  telehealthUrl: "http://localhost:3001",
  orgUrl: "http://localhost:3002",
  reportReviewers: {
    [ReportVertical.SchoolPsych]: [
      { name: "Power User", userId: "c5764191-380d-4b5a-a06c-f171fbf1b3a6", isReportCreator: true },
      { name: "Evan Cook", userId: "e2c3e960-5a2c-49e9-bbf0-2ee243c7cde8", isReportCreator: true },
    ],
    [ReportVertical.Speech]: [
      { name: "Power User", userId: "c5764191-380d-4b5a-a06c-f171fbf1b3a6", isReportCreator: true },
      { name: "Evan Cook", userId: "e2c3e960-5a2c-49e9-bbf0-2ee243c7cde8", isReportCreator: true },
    ],
  },
};

const preprod: EnvStaticConfig & NoOverrideStaticConfig = {
  ablespaceUrl: "https://www.staging.ablespace.io/sso/parallel-learning",
  launchDarklyClientId: "6282c363500b5914bc84c483",
  nexusServerUrl: "https://api.parallel-staging.com",
  pathwayClientUrl: "https://pathway.parallel-staging.com",
  telehealthUrl: "https://telehealth.parallel-staging.com",
  orgUrl: "https://org.parallel-staging.com",
  signInThrottle: { attempts: 10, timeoutMinutes: 1 },
  reportReviewers: {
    [ReportVertical.SchoolPsych]: [
      { name: "Power User", userId: "c5764191-380d-4b5a-a06c-f171fbf1b3a6", isReportCreator: true },
      { name: "Evan Cook", userId: "e2c3e960-5a2c-49e9-bbf0-2ee243c7cde8", isReportCreator: true },
    ],
    [ReportVertical.Speech]: [
      { name: "Power User", userId: "c5764191-380d-4b5a-a06c-f171fbf1b3a6", isReportCreator: true },
      { name: "Evan Cook", userId: "e2c3e960-5a2c-49e9-bbf0-2ee243c7cde8", isReportCreator: true },
      { name: "Test Admin", userId: "ecfe8817-8e16-4504-8659-a460e1421ecb", isReportCreator: true },
    ],
  },
  oauthClientId: "840252519271-uahmrr2vceirsth7kc4b92inlafjcjkp.apps.googleusercontent.com",
};

const prod: EnvStaticConfig & NoOverrideStaticConfig = {
  ablespaceUrl: "https://app.ablespace.io/sso/parallel-learning",
  launchDarklyClientId: "6282c363500b5914bc84c484",
  nexusServerUrl: "https://api.parallellearning.com",
  pathwayClientUrl: "https://pathway.parallellearning.com",
  telehealthUrl: "https://telehealth.parallellearning.com",
  orgUrl: "https://org.parallellearning.com",
  signInThrottle: { attempts: 10, timeoutMinutes: 15 },
  reportReviewers: {
    [ReportVertical.SchoolPsych]: [
      { name: "Theresa Boen", userId: "03ef4f70-1991-4d88-80ac-01fae1fe0051" },
      { name: "Stacie Corder", userId: "76c6ce62-f6e3-427b-915d-42ed20ced7f3" },
      { name: "Taylor Templin Viele", userId: "cf8191ae-3da7-49b0-8c8f-5bc05ad68440" },
      { name: "Maggy Liu", userId: "9e9e1b27-ca01-421d-9819-a7ffae454ed9", isReportCreator: true },
      { name: "Power User", userId: "c5764191-380d-4b5a-a06c-f171fbf1b3a6", isReportCreator: true },
    ],
    [ReportVertical.Speech]: [
      { name: "Shauna Beyer", userId: "842fb2b6-45a2-4867-a5f6-afd6a8afa935" },
      { name: "Roxanna Patterson", userId: "ecb1acd7-b52a-460e-9800-fe6ab7be8636" },
      { name: "Tiffany Williams", userId: "8c2909e6-1d8c-400d-897f-b935b4a01ab7" },
      { name: "Sandra Connors", userId: "068453a0-6b10-45bd-9fed-82f73a02802a" },
      { name: "Karin Koukeyan", userId: "191f1e16-b852-4be4-8cf9-08c32c58cc6a" },
      { name: "Maggy Liu", userId: "9e9e1b27-ca01-421d-9819-a7ffae454ed9", isReportCreator: true },
      { name: "Power User", userId: "c5764191-380d-4b5a-a06c-f171fbf1b3a6", isReportCreator: true },
    ],
  },
  oauthClientId: "1032931660597-k42vr49ji74ftd1ktqi4109quvsgeuic.apps.googleusercontent.com",
};

const global: GlobalStaticConfig = {
  nexusPort: 8080,
  staticAssetsUrl: "https://storage.googleapis.com/parallel-static-assets",
  streamChatKey: "mqu4thx2jrc8",
  supportLines: {
    email: "support@parallellearning.com",
    emailHours: "9am - 8pm ET",
    phone: "(909) 643-8683",
    phoneHours: "9am - 5pm ET",
  },
  progressAppointmentTypeIds: ["bf5d9bd0-bc77-453e-a818-672ce9813fcb", "2786b8c7-ad6b-4488-9b2e-99decc672928"],
  testingSessionAppointmentTypeId: "26fedea8-90c2-48dd-888b-44ccc9ca35bf",
  logHttpRequests: "true",
  logHttpResponses: "true",
  reportWriterHttpTarget: "pathway",
};

export const getStaticConfig = (parallelEnv?: ParallelEnv): StaticConfig & NoOverrideStaticConfig => ({
  ...global,
  ...(isProd(parallelEnv) ? prod : preprod),
  ...(parallelEnv === "local" ? local : {}),
});
