import { ReactNode, useContext, useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import ActivityMenu, { ActivitySubmenuType } from "@/components/meeting/activity/ActivityMenu";
import AssessmentsMenu from "@/components/meeting/activity/assessment/AssessmentsMenu";
import Chat from "@/components/meeting/activity/chat/Chat";
import ProgressSidebarContent from "@/components/meeting/activity/progress/ProgressSidebarContent";
import ProgressSidebarHeader from "@/components/meeting/activity/progress/ProgressSidebarHeader";
import { AlertStoreContext, MeetingStoreContext } from "@/stores";
import { LeftSidebarType, RightSidebarType } from "@/stores/meeting.store";
import { classNames } from "@/utils";
import HelpContent from "./content/HelpContent";
import ParticipantManagerContent from "./content/ParticipantManagerContent";
import SettingsContent from "./content/SettingsContent";

export const DefaultMeetingSidebarHeader = ({
  title,
  onClose,
  subheader,
}: {
  title: string;
  onClose: () => void;
  subheader?: ReactNode;
}) => (
  <div className="w-full h-full p-4 flex flex-row justify-between items-center text-2xl">
    <div className="flex flex-col items-start">
      <span className="text-lg font-semibold">{title}</span>
      {subheader}
    </div>
    <XMarkIcon
      role="button"
      className="h-4 w-4 cursor-pointer"
      aria-label="Close Left Sidebar"
      aria-hidden="true"
      tabIndex={0}
      onClick={onClose}
      onKeyDown={e => e.key === "Enter" && onClose()}
    />
  </div>
);

const MeetingSidebar = ({
  header,
  content,
  className = "w-80",
}: {
  header: ReactNode;
  content: ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames("flex flex-col h-full bg-white border-r border-slate-300 shrink-0", className)}>
      <div className="w-full border-b border-slate-300">{header}</div>
      <div className="w-full flex-1 overflow-hidden">{content}</div>
    </div>
  );
};

const getLeftSidebarProps = (
  type: LeftSidebarType,
  onClose: () => void,
  activitySubmenu: ActivitySubmenuType | undefined,
  setActivitySubmenu: (a?: ActivitySubmenuType) => void,
) => {
  switch (type) {
    case "activities":
      return {
        header: (
          <DefaultMeetingSidebarHeader
            title={activitySubmenu ? `Select ${capitalize(activitySubmenu)}` : "Activities"}
            onClose={onClose}
            subheader={
              activitySubmenu && (
                <button
                  className="text-sm underline cursor-pointer hover:text-ocean"
                  onClick={() => setActivitySubmenu(undefined)}
                >
                  Back to Activities
                </button>
              )
            }
          />
        ),
        content: <ActivityMenu submenu={activitySubmenu} setSubmenu={setActivitySubmenu} />,
      };
    case "assessments":
      return {
        header: <DefaultMeetingSidebarHeader title="Assessments" onClose={onClose} />,
        content: <AssessmentsMenu />,
      };
    case "chat":
      return {
        header: <DefaultMeetingSidebarHeader title="Chat" onClose={onClose} />,
        content: <Chat />,
      };
    case "client-goals":
      return {
        header: <ProgressSidebarHeader />,
        content: <ProgressSidebarContent />,
        className: "w-96",
      };
  }
};

const _LeftSidebar = () => {
  const meetingStore = useContext(MeetingStoreContext);
  const { leftSidebarType: type } = meetingStore;

  const [activitySubmenu, setActivitySubmenu] = useState<ActivitySubmenuType>();

  if (!type) return <></>;

  const props = {
    ...getLeftSidebarProps(type, () => meetingStore.clearLeftSidebar(), activitySubmenu, setActivitySubmenu),
    className: classNames("border-r", type === "client-goals" ? "w-96" : "w-80"),
  };

  return <MeetingSidebar {...props} />;
};

export const LeftSidebar = observer(_LeftSidebar);

const getRightSidebarProps = (type: RightSidebarType, onClose: () => void) => {
  switch (type) {
    case "help":
      return {
        header: <DefaultMeetingSidebarHeader title="Help" onClose={onClose} />,
        content: <HelpContent showAppointment={true} />,
      };
    case "participants":
      return {
        header: <DefaultMeetingSidebarHeader title="Meeting Participants" onClose={onClose} />,
        content: <ParticipantManagerContent />,
      };
    case "settings":
      return {
        header: <DefaultMeetingSidebarHeader title="Settings" onClose={onClose} />,
        content: <SettingsContent />,
      };
  }
};

const _RightSidebar = () => {
  const meetingStore = useContext(MeetingStoreContext);
  const alertStore = useContext(AlertStoreContext);

  const { rightSidebarType: type } = meetingStore;
  const { selectedAlert } = alertStore;

  useEffect(() => {
    if (!!alertStore.selectedAlert && !type) meetingStore.toggleRightSideBar("help");
  }, [selectedAlert]);

  if (!type) return <></>;

  const onClose = () => {
    meetingStore.clearRightSidebar();
    alertStore.setSelected(undefined);
  };
  const props = getRightSidebarProps(type, onClose);

  return <MeetingSidebar {...props} className="w-80 border-l" />;
};

export const RightSidebar = observer(_RightSidebar);
