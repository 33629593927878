import { useContext, useEffect } from "react";
import {
  ScreenShare,
  useActiveSpeakerId,
  useLocalSessionId,
  useParticipantIds,
  useScreenShare,
} from "@daily-co/daily-react";
import { useKeyboardEvent, useMeasure } from "@react-hookz/web";
import { isEqual, partition } from "lodash";
import { observer } from "mobx-react-lite";
import DailyMeetingWindow, { DailyWindowProps } from "@/components/meeting/daily/DailyMeetingWindow";
import MeetingWindowLayout from "@/components/meeting/layout/window/MeetingWindowLayout";
import { LayoutType } from "@/components/meeting/layout/window/util/common";
import resolveSecondaryWindowStyle from "@/components/meeting/layout/window/util/resolve-secondary-window-style";
import config from "@/config";
import { StoreContext } from "@/stores";
import { classNames } from "@/utils";
import { DOCUMENT_CAMERA_TRACK_NAME, isProviderParticipant, shouldDisplayParticipant } from "@/utils/daily.utils";
import { usePlaceholderImages } from "../layout/window/util/placeholder";
import VideoPlaceholder, { VideoImagePlaceholder } from "../video/VideoPlaceholder";
import DailyMeetingFooter from "./DailyMeetingFooter";
import useActivityWindow from "./use-activity-window";

const DailyMeetingWindowLayout = ({ meetingKey }: { meetingKey: string }) => {
  const {
    activityStore: { currActivity },
    alertStore,
    meetingStore: {
      isClientSelfViewEnabled,
      isProviderSelfViewDisabled,
      isProviderScreenshareMinimized,
      activeDocumentCamera,
      pinnedParticipantKey,
    },
    participantStore,
    userStore: { isClient, isStaff },
  } = useContext(StoreContext);

  const allDailySessionIds = useParticipantIds({
    filter: shouldDisplayParticipant,
    onParticipantJoined: () => participantStore.sync(meetingKey, { operation: "participantJoined" }),
  });
  const localSessionId = useLocalSessionId();
  const providerSessionId = useParticipantIds({ filter: isProviderParticipant })[0];
  const { screens } = useScreenShare();
  const activeSpeakerId = useActiveSpeakerId({ ignoreLocal: true });

  const lostFocusParticipants = participantStore.lostFocusParticipants.filter(
    p => p.dailySessionId && allDailySessionIds.includes(p.dailySessionId),
  );
  useEffect(() => {
    if (isClient) return;
    alertStore.updateFocusAlert(lostFocusParticipants, allDailySessionIds.length > 2);
  }, [lostFocusParticipants]);

  const remoteDailySessionIds = allDailySessionIds.filter(id => id !== localSessionId);
  const userWindowProps: DailyWindowProps[] = remoteDailySessionIds.map(id => ({
    type: "session",
    dailySessionId: id,
    isScreenshare: false,
  }));

  const documentCameraWindowProps: DailyWindowProps[] = activeDocumentCamera
    ? [
        {
          type: "document-camera",
          videoTrack: activeDocumentCamera.track,
          displayName: activeDocumentCamera.displayName,
        },
      ]
    : [];

  const remoteParticipants: DailyWindowProps[] = [...userWindowProps, ...documentCameraWindowProps];

  let primaryParticipant: DailyWindowProps | undefined = undefined;
  let secondaryParticipants: DailyWindowProps[] = [...remoteParticipants];

  const pinnedDailySessionId = participantStore.allParticipants.find(
    p => p.key === pinnedParticipantKey,
  )?.dailySessionId;

  const showSelfView = (isClient && isClientSelfViewEnabled) || (isStaff && !isProviderSelfViewDisabled);

  if (screens.length > 0) {
    // always show screenshare as primary by default (preferring remote user), show the rest as secondary windows
    const [localScreens, remoteScreens] = partition(screens, s => s.local);

    const toScreenshareParticipant = (s: ScreenShare): DailyWindowProps => ({
      type: "session",
      dailySessionId: s.session_id,
      isScreenshare: true,
    });
    const primaryScreen = remoteScreens[0] || (!isProviderScreenshareMinimized ? localScreens[0] : undefined);
    const secondaryScreens = [...remoteScreens, ...localScreens].filter(s => s.screenId !== primaryScreen?.screenId);

    if (primaryScreen) primaryParticipant = toScreenshareParticipant(primaryScreen);
    secondaryParticipants = [...secondaryScreens.map(toScreenshareParticipant), ...secondaryParticipants];
  } else if (pinnedDailySessionId && allDailySessionIds.includes(pinnedDailySessionId)) {
    // always show pinned video participant as primary when resolvable + visible
    if (pinnedDailySessionId !== localSessionId || showSelfView) {
      primaryParticipant = {
        type: "session",
        dailySessionId: pinnedDailySessionId,
        isScreenshare: false,
      };
    }
  } else if (pinnedParticipantKey === DOCUMENT_CAMERA_TRACK_NAME) {
    primaryParticipant = documentCameraWindowProps[0];
  } else if (isClient && !!providerSessionId) {
    // always show provider as primary window to clients
    primaryParticipant = {
      type: "session",
      dailySessionId: providerSessionId,
      isScreenshare: false,
    };
  } else if (secondaryParticipants.length === 1) {
    // always show single remote participant as primary window
    primaryParticipant = secondaryParticipants[0];
  }

  // show self-view as first secondary window when enabled
  if (showSelfView)
    localSessionId &&
      secondaryParticipants.unshift({ type: "session", dailySessionId: localSessionId, isScreenshare: false });

  if (primaryParticipant) secondaryParticipants = secondaryParticipants.filter(p => !isEqual(p, primaryParticipant));

  const { window: activityWindow, usesPrimaryParticipant } = useActivityWindow({
    currActivity,
    primaryParticipant,
  });
  const isPinningDisabled =
    (primaryParticipant?.type === "session" && primaryParticipant?.isScreenshare) || !!activityWindow;

  // show primary participant as secondary window if it isn't used by the activity window
  if (!!activityWindow && !usesPrimaryParticipant && !!primaryParticipant)
    secondaryParticipants.unshift(primaryParticipant);

  const { imageUrls: placeholderImageUrls, add: addPlaceholder, remove: removePlaceholder } = usePlaceholderImages();
  useKeyboardEvent(
    e => config.parallelEnv !== "production" && e.altKey && e.code === "KeyP",
    e => (e.ctrlKey ? removePlaceholder() : addPlaceholder()),
  );

  const primaryWindow =
    activityWindow ||
    (primaryParticipant && (
      <DailyMeetingWindow
        {...primaryParticipant}
        isPinningDisabled={isPinningDisabled}
        activeSpeakerId={activeSpeakerId || undefined}
      />
    )) ||
    (remoteParticipants.length === 0 && placeholderImageUrls.length === 0 && (
      <VideoPlaceholder text="Waiting for others to join" />
    ));

  const [secondaryContainerArea, secondaryContainerRef] = useMeasure<HTMLDivElement>();
  const secondaryWindowStyle = resolveSecondaryWindowStyle(
    LayoutType.Equal,
    secondaryParticipants.length + placeholderImageUrls.length,
    secondaryContainerArea,
  );
  const secondaryWindowStyleClass = classNames("p-1", primaryWindow ? "min-h-[20%] max-h-[33%]" : undefined);
  const secondaryWindows = secondaryParticipants.length > 0 && (
    <div
      className={classNames("w-full h-full flex", primaryWindow ? "flex-col" : "flex-row flex-wrap justify-center")}
      ref={secondaryContainerRef}
    >
      {secondaryParticipants.map(p => (
        <div
          className={secondaryWindowStyleClass}
          style={secondaryWindowStyle}
          key={p.type === "session" ? `${p.dailySessionId}_${p.isScreenshare ? "screen" : "video"}` : "document-camera"}
        >
          <DailyMeetingWindow
            {...p}
            isPinningDisabled={isPinningDisabled}
            activeSpeakerId={activeSpeakerId || undefined}
          />
        </div>
      ))}
      {placeholderImageUrls.map((url, i) => (
        <div className={secondaryWindowStyleClass} style={secondaryWindowStyle} key={`placeholder_${i}`}>
          <VideoImagePlaceholder imageUrl={url} />
        </div>
      ))}
    </div>
  );

  return <MeetingWindowLayout primary={primaryWindow} secondary={secondaryWindows} footer={<DailyMeetingFooter />} />;
};

export default observer(DailyMeetingWindowLayout);
