import { useContext, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ObjectiveMetricInput from "@parallel/polygon/components/progress/metric/ObjectiveMetricInput";
import {
  ProcessButton,
  StatusValue,
  useAsyncProcessStatus,
} from "@parallel/polygon/components/shared/input/status.input";
import { ObjectiveMetricBody, StudentObjective } from "@parallel/vertex/types/progress.types";
import { StoreContext } from "@/stores";

const MetricWriteStatus = ({ status }: { status: StatusValue }) => {
  switch (status) {
    case "loading":
      return (
        <>
          <CircularProgress size={16} />
          <Typography variant="body2">Loading</Typography>
        </>
      );
    case "success":
      return (
        <>
          <CheckCircleOutlineIcon />
          <Typography variant="body2">Update Saved</Typography>
        </>
      );
    case "failure":
      return (
        <>
          <ErrorOutlineIcon />
          <Typography variant="body2">Error Saving Update</Typography>
        </>
      );
  }
};

const ProgressSidebarInput = ({ selectedObjective }: { selectedObjective: StudentObjective }) => {
  const { progressStore } = useContext(StoreContext);

  const { status: metricStatus, perform: upsertMetric } = useAsyncProcessStatus(
    (body: ObjectiveMetricBody) => progressStore.upsertObjectiveMetric(selectedObjective.objectiveId, body),
    { resetStatusTimeoutMs: 1000 },
  );

  const [isPendingComplete, setIsPendingComplete] = useState(false);

  if (isPendingComplete) {
    const performComplete = () =>
      progressStore.toggleObjectiveCompleted(selectedObjective).then(() => setIsPendingComplete(false));

    return (
      <Stack gap={2}>
        <Typography variant="body1">
          The student has met this objective. No further data will be captured for this objective.
        </Typography>
        <Typography variant="body1">Are you sure you want to mark this objective achieved?</Typography>
        <Stack direction="row" gap={2} justifyContent="space-between">
          <Button variant="outlined" onClick={() => setIsPendingComplete(false)} sx={{ width: "30%" }}>
            Back
          </Button>
          <ProcessButton
            variant="contained"
            process={performComplete}
            startIcon={<TaskAltIcon />}
            sx={{ width: "70%" }}
          >
            Objective Achieved
          </ProcessButton>
        </Stack>
      </Stack>
    );
  }

  const isCompleted = !!selectedObjective.completedAt;

  return (
    <Stack gap={1}>
      <ObjectiveMetricInput
        compact
        objective={selectedObjective}
        upsertMetric={upsertMetric}
        onNoteUpdated={note => progressStore.onMetricNoteUpdated(selectedObjective.objectiveId, note)}
      />
      {metricStatus ? (
        <Stack width="100%" height={38} direction="row" gap={1} justifyContent="center" alignItems="center">
          <MetricWriteStatus status={metricStatus.value} />
        </Stack>
      ) : isCompleted ? (
        <ProcessButton
          startIcon={<LockOpenIcon />}
          process={() => progressStore.toggleObjectiveCompleted(selectedObjective)}
          loadingOnly
        >
          Re-Open Objective
        </ProcessButton>
      ) : (
        <Button startIcon={<CheckCircleIcon />} onClick={() => setIsPendingComplete(true)}>
          Mark Objective Achieved
        </Button>
      )}
    </Stack>
  );
};

export default ProgressSidebarInput;
