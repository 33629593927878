import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Status, StatusIcon } from "../../shared/input/status.input";
import ObjectiveValueInput from "./ObjectiveValueInput";

const FrequencyInput = ({
  value,
  writeValue,
  status,
  disabled,
  compact,
}: {
  value: number;
  writeValue: (n: number) => Promise<void>;
  status?: Status;
  disabled?: boolean;
  compact?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(value.toString());

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  const attemptWrite = (newValue: number) => writeValue(newValue).catch(() => setInputValue(value.toString()));

  const onInputChange = (stringValue: string) => {
    setInputValue(stringValue);
    const numberValue = parseInt(stringValue);
    if (isNaN(numberValue)) return;
    attemptWrite(numberValue);
  };

  const incrementValue = () => {
    const newValue = value + 1;
    setInputValue(newValue.toString());
    attemptWrite(newValue);
  };

  const resetButton = (
    <Button
      startIcon={<ReplayIcon />}
      onClick={() => onInputChange("0")}
      disabled={disabled}
      size={compact ? "small" : undefined}
    >
      Reset
    </Button>
  );

  return (
    <Stack gap={1} alignItems="center">
      <Stack direction="row" width={200} gap={1} alignItems="center">
        <ObjectiveValueInput
          value={inputValue}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
          compact={compact}
        />
        {compact && resetButton}
      </Stack>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={incrementValue}
        sx={{ width: 200 }}
        disabled={disabled}
      >
        Add
      </Button>
      {!compact && resetButton}
      {status && !compact && <StatusIcon status={status} />}
    </Stack>
  );
};

export default FrequencyInput;
