import { useContext, useEffect } from "react";
import { Notification } from "@parallel/vertex/types/notification.types";
import Button from "@/components/common/elements/Button";
import Modal from "@/components/common/modal/Modal";
import { loggerContext, MeetingStoreContext } from "@/stores";
import { initLogger } from "@/utils/logging.utils";

type NotificationModalOpts = {
  notification: Notification;
};

const logger = initLogger("NotificationModal", loggerContext);

const NotificationModal = ({ notification }: NotificationModalOpts) => {
  const meetingStore = useContext(MeetingStoreContext);

  const { audio, title, body, okButton, link } = notification || {};

  useEffect(() => {
    if (!audio || !audio.url) return;
    new Audio(audio.url).play().catch(e => logger.operationError("playNotificationAudio", e, { url: audio.url }, true));
  }, [audio]);

  if (!notification || !title || !body) return <></>;

  const handleClose = () => meetingStore.markNotificationRead(notification.id);

  return (
    <Modal title={title} onClose={!okButton ? handleClose : undefined} containerStyle={{ minWidth: "30%" }}>
      <span>{body}</span>
      {!!link && (
        <a className="pl-1" href={link.url}>
          <span className="underline font-semibold">{link.label}</span>
        </a>
      )}
      {!!okButton && (
        <div className="flex justify-end">
          <Button text="Ok" onClick={handleClose} />
        </div>
      )}
    </Modal>
  );
};

export default NotificationModal;
