import { keyBy } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { UpsertMetricOptions } from "@parallel/polygon/components/progress/metric/ObjectiveMetricInput";
import { resolveObjectiveCompletionToggleUpdate } from "@parallel/polygon/util/progress.util";
import {
  AppointmentProgress,
  ObjectiveMetricBody,
  StudentAppointmentProgress,
  StudentGoal,
  StudentObjective,
  UpdateGoalBody,
} from "@parallel/vertex/types/progress.types";
import { ProgressAPI } from "@/api/progress.api";
import { AlertStore } from "@/stores/alert.store";
import { MeetingStore } from "@/stores/meeting.store";
import { TelehealthLogger } from "@/utils/logging.utils";

type AppointmentProgressState = AppointmentProgress & { appointmentId: string; selectedStudentId: string };

export class ProgressStore {
  appointmentProgress?: AppointmentProgressState = undefined;
  searchText: string = "";
  onlyShowPinned: boolean = false;

  constructor(
    private alertStore: AlertStore,
    private meetingStore: MeetingStore,
    private progressApi: ProgressAPI,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  get selectedStudentProgress(): StudentAppointmentProgress | undefined {
    if (!this.appointmentProgress) return;

    const selectedStudentId = this.appointmentProgress.selectedStudentId;
    return this.appointmentProgress.students.find(s => s.studentId === selectedStudentId);
  }

  get appointmentId() {
    return this.meetingStore.appointment?.appointmentId;
  }

  public setSearchText = (text: string) => {
    this.searchText = text;
  };

  public togglePinFilter = () => {
    this.onlyShowPinned = !this.onlyShowPinned;
  };

  async loadAppointmentProgress(shouldReload: boolean = false) {
    const appointmentId = this.appointmentId;
    if (!appointmentId) return;

    if (this.appointmentProgress?.appointmentId === appointmentId && !shouldReload) return;

    const currentStudentId = this.appointmentProgress?.selectedStudentId;
    this.appointmentProgress = undefined;

    const progress = await this.progressApi.getAppointmentProgress(appointmentId).catch(e => {
      this.logger.error("error fetching appointment progress", undefined, e);
      this.alertStore.push("Error Fetching Appointment Goals");
      return null;
    });
    if (!progress) return;

    const activeStudent = progress.students.find(s => s.studentId === currentStudentId) || progress.students[0];

    runInAction(() => {
      this.appointmentProgress = {
        ...progress,
        appointmentId,
        selectedStudentId: activeStudent.studentId,
      };
      this.onlyShowPinned = activeStudent.goals.some(goal => goal.objectives.some(o => o.isPinned));
    });
  }

  public selectProgressStudent = (studentId: string) => {
    if (!this.appointmentProgress) return;
    this.appointmentProgress = { ...this.appointmentProgress, selectedStudentId: studentId };
  };

  public updateSelectedStudentGoal = async (goalId: string, update: UpdateGoalBody) => {
    if (!this.appointmentProgress) return;

    const selectedStudent = this.selectedStudentProgress;
    if (!selectedStudent) return;

    const updatedGoal = await this.progressApi.updateStudentGoal(selectedStudent.studentId, goalId, update);

    const currentGoal = this.selectedStudentProgress.goals.find(g => goalId === g.goalId);
    const currentObjectives = currentGoal ? keyBy(currentGoal?.objectives, "objectiveId") : {};
    const mergedObjectives = updatedGoal.objectives.map(o => ({
      ...o,
      metric: currentObjectives[o.objectiveId]?.metric,
    }));

    this.setUpdatedGoal({ ...updatedGoal, objectives: mergedObjectives }, selectedStudent, this.appointmentProgress);
  };

  public toggleObjectiveCompleted = async (objective: StudentObjective) => {
    const parentGoal = this.selectedStudentProgress?.goals.find(g => g.goalId === objective.goalId);
    if (!parentGoal) return;

    const update = resolveObjectiveCompletionToggleUpdate(objective.objectiveId, !objective.completedAt, parentGoal);
    return this.updateSelectedStudentGoal(objective.goalId, update);
  };

  public toggleObjectivePinned = async ({ objectiveId, goalId, isPinned }: StudentObjective) => {
    return this.updateSelectedStudentGoal(goalId, {
      objectives: [{ objectiveId, isPinned: !isPinned }],
    });
  };

  public upsertObjectiveMetric = async (objectiveId: string, body: ObjectiveMetricBody & UpsertMetricOptions) => {
    if (!this.appointmentId) return;

    const currentState = this.getObjectiveUpdateState(objectiveId);
    if (!currentState) return;

    const { currentProgress, selectedStudent, parentGoal } = currentState;
    const updatedObjective = await this.progressApi.upsertObjectiveAppointmentMetric(
      objectiveId,
      this.appointmentId,
      body,
    );
    if (body.skipStateUpdate) return;

    const updatedObjectives = parentGoal.objectives.map(currentObjective =>
      currentObjective.objectiveId === objectiveId ? updatedObjective : currentObjective,
    );
    const updatedGoal = { ...parentGoal, objectives: updatedObjectives };

    this.setUpdatedGoal(updatedGoal, selectedStudent, currentProgress);
  };

  public onMetricNoteUpdated = (objectiveId: string, note: string) => {
    const currentState = this.getObjectiveUpdateState(objectiveId);
    if (!currentState) return;

    const { currentProgress, selectedStudent, parentGoal } = currentState;
    const updatedObjectives: StudentObjective[] = parentGoal.objectives.map(currentObjective =>
      currentObjective.objectiveId === objectiveId && currentObjective.metric
        ? { ...currentObjective, metric: { ...currentObjective.metric, type: "string", value: note } }
        : currentObjective,
    );
    const updatedGoal = { ...parentGoal, objectives: updatedObjectives };

    this.setUpdatedGoal(updatedGoal, selectedStudent, currentProgress);
  };

  private getObjectiveUpdateState = (objectiveId: string) => {
    if (!this.appointmentProgress) return;

    const selectedStudent = this.selectedStudentProgress;
    if (!selectedStudent) return;

    const parentGoal = selectedStudent.goals.find(g => g.objectives.some(o => o.objectiveId === objectiveId));
    if (!parentGoal) return;

    return { currentProgress: this.appointmentProgress, selectedStudent, parentGoal };
  };

  public setCurrentStudentNote = async (note: string) => {
    if (!this.appointmentId || !this.appointmentProgress) return;

    const selectedStudent = this.selectedStudentProgress;
    if (!selectedStudent) return;

    this.setUpdatedStudent({ note }, this.appointmentProgress);

    await this.progressApi.setStudentNote(selectedStudent.studentId, this.appointmentId, note);
  };

  private setUpdatedStudent = (
    update: Partial<StudentAppointmentProgress>,
    currentProgress: AppointmentProgressState,
  ) => {
    const updatedStudents = currentProgress.students.map(currentStudent =>
      currentStudent.studentId === currentProgress.selectedStudentId
        ? { ...currentStudent, ...update }
        : currentStudent,
    );
    this.appointmentProgress = { ...currentProgress, students: updatedStudents };
  };

  private setUpdatedGoal = (
    updated: StudentGoal,
    currentStudent: StudentAppointmentProgress,
    currentProgress: AppointmentProgressState,
  ) => {
    const updatedGoals = currentStudent.goals.map(currentGoal =>
      currentGoal.goalId === updated.goalId ? updated : currentGoal,
    );
    this.setUpdatedStudent({ goals: updatedGoals }, currentProgress);
  };
}
