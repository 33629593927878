import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PropagateLoader } from "react-spinners";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { SignInError } from "@parallel/vertex/util/nexus.base.api";
import Footer from "@/components/common/layout/Footer";
import { SimpleHeaderLayout } from "@/components/common/layout/HeaderLayout";
import config from "@/config";
import { ApiStoreContext, UserStoreContext, loggerContext } from "@/stores";
import { classNames } from "@/utils";
import { initLogger, useEventRedirect } from "@/utils/logging.utils";

type CodeFormInput = {
  shortCode: string;
};

const logger = initLogger("CodeScreen", loggerContext);

const CodeScreen = () => {
  useMountEffect(() => logger.postEvent("PageReady", "code join screen ready"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
    setValue,
  } = useForm<CodeFormInput>({ mode: "onChange" });

  useEffect(() => {
    setFocus("shortCode");
  }, [setFocus]);

  const redirect = useEventRedirect(logger);

  const { authApi } = useContext(ApiStoreContext);
  const userStore = useContext(UserStoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<CodeFormInput> = async data => {
    if (data.shortCode.length !== 5) {
      setError("shortCode", { type: "manual", message: "Code must be 5 characters" });
      return;
    }
    const uppercaseCode = data.shortCode.toUpperCase();
    setIsLoading(true);
    authApi
      .postAppointmentCodeSignIn(uppercaseCode, userStore.signInAttempt())
      .then(r => {
        !!r && userStore.setAuthorizedAppointmentCode(r);

        /**
         * This is functional, but postAppointmentKeySignIn() is performed again on the /s/:shortCode route
         * @TODO: Persist this to state and head to the appropriate lobby screen without duplicating the
         * postAppoitmentKeySignIn() call
         */
        redirect(`/s/${uppercaseCode}`, "client meeting join");
      })
      .catch((e: SignInError) => {
        logger.operationError("postAppointmentKeySignIn", e, {
          shortCode: data.shortCode,
          encryptionType: "shortcode",
        });

        logger.warn(`error signing in with shortcode`, { shortCode: data.shortCode, apiError: e.causedBy?.message }, e);
        setIsLoading(false);
        const message = e.isRateLimit ? "Too many requests, please try agin later" : "Invalid code, please try again";
        setError("shortCode", {
          type: "manual",
          message,
        });
      });
  };

  return (
    <SimpleHeaderLayout>
      <div className="w-full h-full bg-gray-100 relative">
        <div className="w-full h-2/3 flex h-screen grid place-items-center">
          <div className="w-[460px] p-6 bg-white rounded-md border border-gray-300 text-center">
            <img
              src={`${config.staticAssetsUrl}/logos/parallel-wordmark-black.svg`}
              alt="Parallel Logo"
              className="w-52"
            />
            <h1 className="text-xl mt-10">Join your session</h1>
            <div>Enter your session code to join the lobby</div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-6 mt-6 flex flex-col justify-center items-center"
              aria-label="login"
              autoComplete="off"
            >
              <div>
                <input
                  {...register("shortCode", {
                    pattern: {
                      value: /^[a-zA-Z0-9]+$/,
                      message: "Code must only include letters and numbers",
                    },
                  })}
                  placeholder="Enter code"
                  className={classNames(
                    "appearance-none block w-56 px-6 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400",
                    "sm:text-2xl tracking-[0.5em] uppercase text-center font-bold",
                    "focus:outline-none",
                    errors.shortCode ? "focus:border-red" : "focus:border-indigo-500",
                    "placeholder:tracking-normal placeholder:text-center",
                  )}
                  id="shortCode"
                  maxLength={5}
                  aria-invalid={errors.shortCode ? "true" : "false"}
                  onPaste={e => {
                    e.preventDefault();
                    const input = e.clipboardData.getData("text");
                    const match = input.match(/.*\/s\/([a-zA-Z0-9]{5})$/);
                    const code = match && match[1];
                    if (code) setValue("shortCode", code);
                    if (input.length <= 5) setValue("shortCode", input);
                  }}
                />
              </div>
              {errors.shortCode && (
                <div className="mb-3 text-normal text-red">
                  <span className="flex flex-row gap-2 justify-center items-center">
                    <XCircleIcon className="w-5 h-5" />
                    {errors.shortCode.message || "Invalid Code"}
                  </span>
                </div>
              )}

              <button
                className={`bg-ocean border border-ocean text-white text-center outline-ocean w-3/5 h-12 my-2 px-8 rounded-xl hover:font-bold`}
                type="submit"
                aria-label="join"
                disabled={isLoading}
              >
                {!isLoading ? "Join Session" : <PropagateLoader color="white" className="pb-3" />}
              </button>
            </form>
          </div>
          <div className="flex-auto flex-col gap-4 items-center p-12"></div>
        </div>
        <Footer shouldFloat={true} />
      </div>
    </SimpleHeaderLayout>
  );
};

export default observer(CodeScreen);
