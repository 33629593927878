import { useContext } from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import LoginForm from "@/components/login/LoginForm";
import { AlertStoreContext } from "@/stores";
import Modal from "./Modal";

const GlobalFailureModal = () => {
  const alertStore = useContext(AlertStoreContext);
  const navigate = useNavigate();

  const onLoginSuccess = () => {
    const shouldReloadPage = alertStore.shouldReloadOnLogin;
    alertStore.handleLoginSuccess();
    // refresh page if a reload is required to fetch data
    if (shouldReloadPage) {
      navigate(0); // `0` in useNavigate reloads the current page
    }
  };

  if (alertStore.isSessionExpired)
    return (
      <Modal title="Session Expired" containerStyle={{ width: "70%", height: "90%" }}>
        <div className="flex flex-col gap-4 place-items-center">
          <p>Your sign-in session has expired. To continue using the app, please sign in again.</p>
          <LoginForm onSuccess={() => onLoginSuccess()} />
        </div>
      </Modal>
    );

  return <></>;
};

export default observer(GlobalFailureModal);
