import { AppointmentCodeSignIn, AuthorizedUser } from "@parallel/vertex/types/auth.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { SignInError } from "@parallel/vertex/util/nexus.base.api";
import config from "@/config";
import { BaseAPI, BaseApiOptions } from "./base.api";

export class PasswordSignInError extends SignInError {
  constructor(apiError: any, attemptCount: number) {
    super(apiError, "telehealth", { ...config.signInThrottle, attemptCount });
  }
}

export class AppointmentCodeSignInError extends SignInError {
  isInvalid: boolean = false;
  constructor(apiError: any, attemptCount?: number) {
    super(apiError, "telehealth", attemptCount ? { ...config.signInThrottle, attemptCount } : undefined);
    if (apiError.response?.status === 404) {
      this.message = "Appointment link is not valid";
      this.isInvalid = true;
    } else if (!attemptCount) {
      this.message = "Unexpected error signing in via appointment code";
    }
  }
}

export class AuthAPI extends BaseAPI {
  constructor(options: BaseApiOptions) {
    super({ ...options, parseTimestamps: true });
  }

  public postPasswordSignIn = (email: string, password: string, attemptCount: number): Promise<AuthorizedUser> =>
    this.instance
      .post("auth/password", { email, password })
      .then(r => r.data)
      .catch(err => {
        throw new PasswordSignInError(err, attemptCount);
      });

  public postGoogleSignIn = (token: string): Promise<AuthorizedUser> =>
    this.instance.post("auth/google", { token }).then(r => r.data);

  public postAppointmentCodeSignIn = (code: string, attemptCount?: number): Promise<AppointmentCodeSignIn> =>
    this.instance
      .post(`auth/appointment-code/${encodeURIComponent(code)}`)
      .then(r => r.data)
      .catch(err => {
        throw new AppointmentCodeSignInError(err, attemptCount);
      });

  public restoreSignIn = (): Promise<AuthorizedUser> => this.instance.post("auth/restore").then(r => r.data);

  public postSignOut = (): Promise<void> => this.instance.delete("auth");

  public getCurrentUser = (): Promise<ExtendedUser> => this.instance.get("auth/current-user").then(r => r.data);
}
