import { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/Launch";
import PushPinIcon from "@mui/icons-material/PushPin";
import RefreshIcon from "@mui/icons-material/Refresh";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { getFullNameInitials } from "@parallel/vertex/util/string.util";
import config from "@/config";
import { StoreContext } from "@/stores";

const ProgressSidebarHeader = () => {
  const { progressStore } = useContext(StoreContext);
  const { appointmentId, appointmentProgress, searchText, onlyShowPinned } = progressStore;

  return (
    <Stack width="100%" p={1} gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Link
          href={appointmentId ? `${config.pathwayClientUrl}/calendar/appointment/${appointmentId}/progress` : undefined}
          underline="hover"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h6">Goals</Typography>
            <LaunchIcon fontSize="small" />
          </Stack>
        </Link>

        <Stack direction="row" gap={1}>
          {appointmentProgress?.students.map(student => {
            const isSelected = student.studentId === appointmentProgress.selectedStudentId;
            return (
              <Avatar
                sx={{
                  height: 32,
                  width: 32,
                  bgcolor: isSelected ? "surface.dark" : undefined,
                  fontSize: 16,
                  cursor: isSelected ? undefined : "pointer",
                  "&:hover": {
                    textDecoration: isSelected ? undefined : "underline",
                  },
                }}
                onClick={() => progressStore.selectProgressStudent(student.studentId)}
                key={student.studentId}
              >
                {getFullNameInitials(student.studentName)}
              </Avatar>
            );
          })}
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1}>
        <TextField
          label="Search"
          value={searchText}
          onChange={e => progressStore.setSearchText(e.target.value)}
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => progressStore.setSearchText("")} edge="end">
                  <CloseIcon fontSize="small" sx={{ color: "grey.400" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title={onlyShowPinned ? "Show All" : "Only Show Pinned"}>
          <PushPinIcon
            onClick={progressStore.togglePinFilter}
            sx={{
              color: onlyShowPinned ? "primary.main" : "grey.300",
              cursor: "pointer",
              "&:hover": { color: "primary.dark" },
            }}
          />
        </Tooltip>
        <Tooltip title="Refresh Data">
          <RefreshIcon
            onClick={() => progressStore.loadAppointmentProgress(true)}
            sx={{
              cursor: "pointer",
              color: "primary.main",
              "&:hover": { color: "primary.dark" },
            }}
          />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default observer(ProgressSidebarHeader);
