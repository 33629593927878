import { FormEvent, ReactNode, useCallback, useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { debounce, get, isNull, isObject, omit } from "lodash";
import { SelectOption } from "./AutoCompleteInput";

export type StatusValue = "loading" | "success" | "failure";

export type Status = {
  value: StatusValue;
  message?: string;
};

const getIcon = (status: Status | undefined, size: number, defaultIcon: ReactNode = <></>, noColor?: boolean) => {
  switch (status?.value) {
    case "loading":
      return <CircularProgress size={size} />;
    case "failure":
      return <ErrorIcon color={noColor ? undefined : "error"} sx={{ fontSize: size }} />;
    case "success":
      return <CheckCircleIcon color={noColor ? undefined : "success"} sx={{ fontSize: size }} />;
    default:
      return <>{defaultIcon}</>;
  }
};

export const StatusIcon = ({ status, size = 24 }: { status?: Status; size?: number }) => {
  const icon = getIcon(status, size);
  if (!status?.message) return icon;
  return <Tooltip title={status.message}>{icon}</Tooltip>;
};

type AsyncProcess<P> = (p: P) => Promise<{ status?: StatusValue } | unknown>;

type AsyncProcessOptions = { resetStatusTimeoutMs?: number | null; throwError?: boolean };

export const useAsyncProcessStatus = <P,>(
  process: AsyncProcess<P>,
  { resetStatusTimeoutMs = 5000, throwError }: AsyncProcessOptions = {},
) => {
  const [status, setStatus] = useState<Status>();
  const perform = (p: P) => {
    setStatus({ value: "loading" });
    return process(p)
      .then(result => {
        setStatus({ value: get(isObject(result) ? result : {}, "status", "success") });
      })
      .catch(e => {
        setStatus({ value: "failure" });
        if (throwError) throw e;
      })
      .finally(() => !isNull(resetStatusTimeoutMs) && setTimeout(() => setStatus(undefined), resetStatusTimeoutMs));
  };
  return { status, perform, setStatus };
};

export const useDebouncedProcessStatus = <P,>(
  process: AsyncProcess<P>,
  { saveKey, wait = 1000 }: { saveKey: string; wait?: number },
  options?: AsyncProcessOptions,
) => {
  const { status, perform, setStatus } = useAsyncProcessStatus(process, { ...options, resetStatusTimeoutMs: null });

  // manually managing save status timeout b/c debounce prevents us from using default behavior of `useAsyncProcessStatus`
  const [statusTimeout, setStatusTimeout] = useState<NodeJS.Timeout>();
  useEffect(() => {
    if (!status) return;
    if (status.value === "loading") {
      clearTimeout(statusTimeout);
      setStatusTimeout(undefined);
    } else {
      setStatusTimeout(
        setTimeout(() => {
          setStatus(undefined);
          setStatusTimeout(undefined);
        }, options?.resetStatusTimeoutMs || 2000),
      );
    }
  }, [status]);

  const debouncedPerform = useCallback(debounce(perform, wait), [saveKey]);

  return {
    status,
    perform: (p: P) => {
      setStatus({ value: "loading" });
      return debouncedPerform(p);
    },
    setStatus,
  };
};

export const useFormStatus = (submit: () => Promise<unknown>) => {
  return useAsyncProcessStatus(
    (event: FormEvent) => {
      event.preventDefault();
      return submit();
    },
    { resetStatusTimeoutMs: null },
  );
};

export const StatusButton = (props: ButtonProps & { status?: Status; loadingOnly?: boolean }) => {
  const startIcon =
    props.loadingOnly && props.status?.value !== "loading"
      ? props.startIcon
      : getIcon(props.status, 20, props.startIcon, true);

  return (
    <Button
      {...omit(props, "status", "loadingOnly")}
      disabled={props.disabled || props.status?.value === "loading"}
      startIcon={startIcon}
    />
  );
};

export const ProcessButton = (props: ButtonProps & { process?: () => Promise<unknown>; loadingOnly?: boolean }) => {
  const { status, perform } = useAsyncProcessStatus(props.process || (async () => {}));
  return <StatusButton {...omit(props, "process")} onClick={perform} status={status} />;
};

export const ProcessIconButton = (
  props: IconButtonProps & { process?: () => Promise<unknown>; loadingOnly?: boolean },
) => {
  const { status, perform } = useAsyncProcessStatus(props.process || (async () => {}));

  const icon =
    props.loadingOnly && status?.value !== "loading" ? props.children : getIcon(status, 16, props.children, true);

  return (
    <IconButton
      {...omit(props, "process", "loadingOnly", "children")}
      onClick={perform}
      disabled={props.disabled || status?.value === "loading"}
    >
      {icon}
    </IconButton>
  );
};

export const SelectWithStatus = ({
  label,
  options,
  selectedKey,
  onSelect,
  minWidth,
  fullWidth,
  readOnly,
  disabled,
}: {
  label: string;
  options: (SelectOption & { icon?: ReactNode; disabled?: boolean })[];
  selectedKey: string;
  onSelect: (key: string) => Promise<unknown>;
  minWidth?: number;
  fullWidth?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}) => {
  const { status, perform } = useAsyncProcessStatus(onSelect);
  const labelId = `${label.toLowerCase().replace(/ /g, "-")}-status-select`;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <FormControl sx={{ minWidth: fullWidth ? "100%" : minWidth }} size="small">
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          label={label}
          value={selectedKey}
          onChange={e => perform(e.target.value)}
          disabled={disabled}
          readOnly={readOnly}
          endAdornment={
            <InputAdornment position="end" sx={{ marginRight: 3 }}>
              <StatusIcon status={status} />
            </InputAdornment>
          }
        >
          {options.map(({ key, label, icon, disabled: isOptionDisabled }) => (
            <MenuItem
              value={key}
              key={key}
              disabled={isOptionDisabled}
              sx={{
                textDecoration: isOptionDisabled ? "line-through" : "none",
              }}
            >
              <ListItemText>{label}</ListItemText>
              {icon}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
