import { get, keyBy, mapValues, partition } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { MeetingParticipant, MeetingParticipants } from "@parallel/vertex/types/meeting.types";
import { sortNumerically } from "@parallel/vertex/util/collection.util";
import { MeetingAPI } from "@/api/meeting.api";
import { TelehealthLogger } from "@/utils/logging.utils";
import { MeetingStore } from "./meeting.store";

type ParticipantUpdateOptions = {
  skipSync?: boolean;
  logParams?: any;
};

type ParticipantWriteOperation =
  | "setDailyParticipantId"
  | "setWhiteboardMode"
  | "setGameMode"
  | "surveySent"
  | "surveyReceived"
  | "surveySubmitted"
  | "setCameras"
  | "focusOn"
  | "focusOff"
  | "videoOn"
  | "videoOff"
  | "audioOn"
  | "audioOff";

export class MeetingParticipantStore {
  triggerRemoteSync?: (operation: ParticipantWriteOperation, logParams?: any) => unknown = undefined;

  localParticipantKey?: string = undefined;
  participants: MeetingParticipants = {};

  pendingFeedbackReceipts?: Record<string, { viewedAt: Date | null }>;

  constructor(
    private meetingStore: MeetingStore,
    private meetingApi: MeetingAPI,
    private logger: TelehealthLogger,
  ) {
    makeAutoObservable(this);
  }

  get localParticipant() {
    if (!this.localParticipantKey) return undefined;
    return get(this.participants, this.localParticipantKey);
  }

  get localFeedbackSurveyStatus() {
    const surveyStatus = this.localParticipant?.feedbackSurveyStatus;
    if (!surveyStatus) return null;
    if (surveyStatus.submittedAtEpochMs) return "submitted";
    if (surveyStatus.receivedAtEpochMs) return "in-progress";
    return "pending";
  }

  get allParticipants() {
    return Object.values(this.participants);
  }

  get remoteParticipants() {
    return this.allParticipants.filter(p => p.key !== this.localParticipantKey);
  }

  get sortedParticipantKeys() {
    const getJoinedAt = (key: string) => this.participants[key].joinedAtEpochMs;
    const [withJoinTime, withoutJoinTime] = partition(Object.keys(this.participants), getJoinedAt);
    const joinTimeSorted = sortNumerically(withJoinTime, getJoinedAt);
    const nameSorted = withoutJoinTime.sort();
    return [...nameSorted, ...joinTimeSorted];
  }

  get streamWhiteboardColors() {
    const zipped = this.sortedParticipantKeys.map((key, i) => {
      const color = DISTINCT_WHITEBOARD_COLORS[i % DISTINCT_WHITEBOARD_COLORS.length];
      return { key, color };
    });
    return mapValues(keyBy(zipped, "key"), "color");
  }

  get localParticipantWhiteboardColor() {
    if (!this.localParticipantKey) return undefined;
    return get(this.streamWhiteboardColors, this.localParticipantKey);
  }

  get clientFeedbackFormStatus() {
    const pendingReceipts = this.pendingFeedbackReceipts;
    if (!pendingReceipts) return null;
    const pendingKey = Object.keys(pendingReceipts).find(k => !pendingReceipts[k].viewedAt);
    return pendingKey ? "pending" : "finished";
  }

  get lostFocusParticipants() {
    return this.allParticipants?.filter(p => p?.hasLostFocus) || [];
  }

  sync = async (meetingKey: string, { operation = "unknown", logParams, syncId }: any) => {
    const { participants } = await this.meetingApi.getMeetingState(meetingKey);
    runInAction(() => (this.participants = participants));

    this.logger.info(`successfully received ${operation} participant state sync`, {
      operation,
      logParams,
      participants,
      syncId,
    });
  };

  updateParticipant = async (
    participantKey: string,
    update: Partial<MeetingParticipant>,
    operation: ParticipantWriteOperation,
    options?: ParticipantUpdateOptions,
  ) => {
    return this.updateParticipants({ [participantKey]: update }, operation, {
      ...options,
      logParams: { ...options?.logParams, participantKey, update },
    });
  };

  updateLocalParticipant = async (
    update: Partial<MeetingParticipant>,
    operation: ParticipantWriteOperation,
    options?: ParticipantUpdateOptions,
  ) => {
    if (!this.localParticipantKey) return;
    return this.updateParticipant(this.localParticipantKey, update, operation, {
      ...options,
      logParams: { ...options?.logParams, isLocal: true },
    });
  };

  private updateParticipants = async (
    request: Record<string, Partial<MeetingParticipant>>,
    operation: ParticipantWriteOperation,
    { skipSync, logParams }: ParticipantUpdateOptions = {},
  ) => {
    try {
      const { connectedMeetingKey: meetingKey } = this.meetingStore;
      if (!meetingKey) return;

      const updatedLocal = Object.keys(request).reduce(
        (currParticipants, nextKey) => ({
          ...currParticipants,
          [nextKey]: {
            ...this.participants[nextKey],
            ...request[nextKey],
          },
        }),
        this.participants,
      );
      this.participants = updatedLocal;

      const { participants: updatedRemote } = await this.meetingApi.updateMeetingParticipants(meetingKey, request);

      if (!skipSync) {
        if (!this.triggerRemoteSync) throw new Error("participant state update remote trigger not set");
        this.triggerRemoteSync(operation, logParams);
      }

      this.logger.info(`successfully triggered ${operation} participant state ${skipSync ? "write" : "sync"}`, {
        operation,
        logParams,
        updatedLocal,
        updatedRemote,
        skipSync,
      });
    } catch (e: any) {
      this.logger.error(`error triggering ${operation} participant state ${skipSync ? "write" : "sync"}`, {
        operation,
        logParams,
        error: e.message,
        request,
      });
    }
  };

  sendClientFeedbackSurvey = (recipientKeys: string[]) => {
    if (recipientKeys.length === 0) return;
    const sentAtEpochMs = Date.now();
    const stateUpdates = recipientKeys.reduce(
      (currUpdate, nextKey) => ({
        ...currUpdate,
        [nextKey]: { feedbackSurveyStatus: { sentAtEpochMs } },
      }),
      {},
    );

    return this.updateParticipants(stateUpdates, "surveySent");
  };

  receivedClientFeedbackSurvey = () => {
    if (!this.localParticipantKey) return;
    this.updateParticipant(
      this.localParticipantKey,
      { feedbackSurveyStatus: { receivedAtEpochMs: Date.now() } },
      "surveyReceived",
    );
  };

  submittedClientFeedbackSurvey = () => {
    if (!this.localParticipantKey) return;
    this.updateParticipant(
      this.localParticipantKey,
      { feedbackSurveyStatus: { submittedAtEpochMs: Date.now() } },
      "surveySubmitted",
    );
  };
}

// generated by https://mokole.com/palette.html
const DISTINCT_WHITEBOARD_COLORS = [
  "#ff0000", // red
  "#0000ff", // blue
  "#008000", // green
  "#ffa500", // orange
  "#8a2be2", // blueviolet
  "#00ff00", // lime
  "#b03060", // maroon3
  "#00ff7f", // springgreen
  "#dc143c", // crimson
  "#00ffff", // aqua
  "#f08080", // lightcoral
  "#adff2f", // greenyellow
  "#ff00ff", // fuchsia
  "#1e90ff", // dodgerblue
  "#eee8aa", // palegoldenrod
  "#90ee90", // lightgreen
  "#ff1493", // deeppink
  "#7b68ee", // mediumslateblue
  "#ee82ee", // violet
  "#228b22", // forestgreen
  "#7f0000", // maroon2
  "#808000", // olive
  "#483d8b", // darkslateblue
  "#008b8b", // darkcyan
  "#cd853f", // peru
  "#4682b4", // steelblue
];
