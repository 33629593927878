import { useLocation, useNavigate } from "react-router";
import { captureMessage } from "@sentry/react";
import { AxiosError } from "axios";
import { forEach, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { FrontendEventType, FrontendFeature, FrontendLogBody } from "@parallel/vertex/types/logging.types";
import { FriendlyApiError, NexusError } from "@parallel/vertex/util/nexus.base.api";
import { loggingApi } from "@/api/logging.api";

export type LogLevel = "info" | "warn" | "error";

type LogFn = (message: string, context?: any, error?: any) => void;

type ReportIssueFn = (
  message: string,
  context?: any,
  options?: { severity?: "warning" | "error"; skipLog?: boolean; causedBy?: Error },
) => void;

type ClientLogger = {
  info: LogFn;
  warn: LogFn;
  error: LogFn;
  reportIssue: ReportIssueFn;
  operationError: (operationName: string, error: any, context?: any, isWarning?: boolean) => void;
  operationSuccess: (operationName: string, context?: any) => void;
  operationResult: (operationName: string, context?: any, error?: any) => void;
  operationSuccessHandler: <A>(
    operationName: string,
    context?: any,
    transformResult?: (r: A) => any,
  ) => (result: A) => A;
  wrapOperation: <A>(
    operationName: string,
    operation: Promise<A>,
    context?: any,
    transformResult?: (r: A) => any,
  ) => Promise<A>;
  mount: (screenName: string) => void;
  redirect: (path: string, reason: string) => void;
  updateContext: (newContextFn: () => any) => ClientLogger;
};

const initClientLogger = ({
  source,
  tag,
  postLog,
  captureIssueMessage,
  commonContext = () => {},
}: {
  source: "Telehealth" | "Org";
  tag: string;
  postLog: (log: FrontendLogBody) => Promise<void>;
  captureIssueMessage: (message: string, c: { level: "warning" | "error"; contexts?: any }) => void;
  commonContext?: () => any;
}): ClientLogger => {
  const logFn =
    (level: LogLevel) =>
    (message: string, context: any = {}, error?: any) => {
      if (error) context.causedBy = error.message;
      console[level](`[${tag}] ${message}`, context);
      postLog({
        source,
        level,
        tag,
        message,
        payload: {
          timestamp: DateTime.utc(),
          ...commonContext(),
          ...context,
        },
      }).catch(e => {
        console.warn(`failed to post log: ${message}`);
        captureIssueMessage("failed to post log", {
          level: "warning",
          contexts: { log: { message }, logger: context, cause: { message: e.message } },
        });
      });
    };
  const info = logFn("info");
  const warn = logFn("warn");
  const error = logFn("error");
  const reportIssue: ReportIssueFn = (message, context, { skipLog, severity = "error", causedBy } = {}) => {
    !skipLog && error(!causedBy ? message : `${message} - caused by: ${causedBy.message}`, context);
    captureIssueMessage(message, {
      level: severity,
      contexts: { logger: context, cause: !causedBy ? undefined : { message: causedBy.message } },
    });
  };
  const operationSuccess = (operationName: string, context?: any) => {
    info(`successfully performed ${operationName}`, { ...context, operationName });
  };
  const operationError = (operationName: string, error: any, context?: any, isWarning?: boolean) => {
    if (error instanceof FriendlyApiError || error instanceof NexusError) error = error.causedBy;
    if (error instanceof AxiosError || error.name === "AxiosError") {
      warn(`http error performing ${operationName}: ${error.message}`, {
        ...context,
        response: error.response,
        operationName,
      });
    } else {
      const message = `unexpected error performing ${operationName}`;
      const logContext = { ...context, operationName };
      isWarning ? warn(message, logContext, error) : reportIssue(message, logContext, { causedBy: error });
    }
  };
  const operationSuccessHandler =
    <A>(operationName: string, context?: any, transformResult: (r: A) => any = r => r) =>
    (result: A) => {
      operationSuccess(operationName, { ...context, operationName, result: transformResult(result) });
      return result;
    };
  return {
    info,
    warn,
    error,
    reportIssue,
    operationSuccess,
    operationResult: (operationName: string, context?: any, error?: any) =>
      !error ? operationSuccess(operationName, context) : operationError(operationName, error, context),
    operationSuccessHandler,
    operationError,
    wrapOperation: <A>(operationName: string, operation: Promise<A>, context?: any, transformResult?: (r: A) => any) =>
      operation.then(operationSuccessHandler(operationName, context, transformResult)).catch(e => {
        operationError(operationName, e, context);
        throw e;
      }),
    mount: componentName => info(`mounted ${componentName}`, { componentName, currUrl: window.location.href }),
    redirect: (toPath, reason) => info(`redirecting to ${toPath}`, { toPath, reason, currUrl: window.location.href }),
    updateContext: contextFn =>
      initClientLogger({
        source,
        tag,
        postLog,
        captureIssueMessage,
        commonContext: contextFn,
      }),
  };
};

type RedirectOptions = { queryParams?: Record<string, string>; clearParams?: boolean };

export type RedirectFn = (toUrl: string, reason: string, options?: RedirectOptions) => void;

const useLogFnRedirect = (logFn: (msg: string, context: any) => void): RedirectFn => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (toUrl, reason, { queryParams = {}, clearParams } = {}) => {
    let url = toUrl;
    if (!clearParams && (!!search || !isEmpty(queryParams))) {
      const params = new URLSearchParams(search);
      forEach(queryParams, (value, key) => params.set(key, value));
      url += `?${params}`;
    }
    logFn(`redirecting to ${toUrl}`, { toUrl, reason, queryParams, currUrl: window.location.href });
    navigate(url);
  };
};

export type TelehealthLogger = ClientLogger & {
  postEvent: (type: FrontendEventType, message: string, context?: any, feature?: FrontendFeature) => void;
};

export const initLogger = (tag: string, commonContext?: () => any): TelehealthLogger => {
  const postEvent = (type: FrontendEventType, message: string, context?: any, feature?: FrontendFeature) => {
    let logMethod: "log" | "error" | "warn" = "log";
    if (type === "Error") logMethod = "error";
    if (type === "Warning") logMethod = "warn";
    console[logMethod](`[${tag}] meeting event: ${type} - ${message}`, context);
    loggingApi
      .postEvent({
        type,
        timestamp: DateTime.utc(),
        log: {
          source: "Telehealth",
          tag,
          message,
        },
        context: {
          ...(commonContext && commonContext()),
          ...context,
        },
        feature,
      })
      .catch(e => {
        console.warn(`failed to post meeting event: ${message}`);
        captureMessage("failed to post meeting event", {
          level: "warning",
          contexts: { event: { message }, logger: context, cause: { message: e.message } },
        });
      });
  };
  return {
    ...initClientLogger({
      source: "Telehealth",
      tag,
      postLog: loggingApi.postLog,
      captureIssueMessage: captureMessage,
      commonContext,
    }),
    postEvent,
    redirect: (toPath: string, reason: string) =>
      postEvent("Navigate", `redirecting to ${toPath}`, {
        toPath,
        reason,
        currUrl: window.location.href,
      }),
  };
};

export const useEventRedirect = (logger: TelehealthLogger) =>
  useLogFnRedirect((msg, context) => logger.postEvent("Navigate", msg, context));
