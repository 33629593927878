import { STATIC_CONFIG_KEYS, StaticConfig } from "./static.config";

const VALID_ENVS = ["local", "staging", "production", "test"] as const;
export type ParallelEnv = (typeof VALID_ENVS)[number];

export type EnvConfig = { parallelEnv: ParallelEnv; isFnTest: boolean };

const parseParallelEnv = (rawEnv: any): ParallelEnv => {
  const rawValue = rawEnv["VITE_PARALLEL_ENV"] || rawEnv["PARALLEL_ENV"] || rawEnv["REACT_APP_PARALLEL_ENV"];
  if (rawValue && VALID_ENVS.includes(rawValue)) return rawValue;
  console.warn(
    "missing valid PARALLEL_ENV env variable (possible values: `staging`, `production`, `test`, `local`) - defaulting to `local`",
  );
  return "local";
};

const toEnvKey = (camelCaseKey: string) => camelCaseKey.replace(/([A-Z])/g, "_$1").toUpperCase();

export const getEnvConfig = (rawEnv: any): EnvConfig & Partial<StaticConfig> => {
  const initial: EnvConfig = { parallelEnv: parseParallelEnv(rawEnv), isFnTest: !!rawEnv["FN_TEST"] };
  return STATIC_CONFIG_KEYS.reduce((currConfig, nextKey) => {
    const envKey = toEnvKey(nextKey);
    const nextValue = rawEnv[envKey] || rawEnv[`VITE_${envKey}`];
    return nextValue ? { ...currConfig, [nextKey]: nextValue } : currConfig;
  }, initial);
};

export const isProd = (env?: ParallelEnv) => env === "production";
