import { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import StopIcon from "@mui/icons-material/Stop";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { isUndefined, pad } from "lodash";
import { secondsToString } from "@parallel/vertex/util/number.util";
import { Status, StatusIcon } from "../../shared/input/status.input";
import ObjectiveValueInput from "./ObjectiveValueInput";

const DurationInput = ({
  value,
  writeValue,
  status,
  disabled,
  compact,
}: {
  value: number;
  writeValue: (n: number) => Promise<void>;
  status?: Status;
  disabled?: boolean;
  compact?: boolean;
}) => {
  const stringToSeconds = (s: string) => {
    const [minutes, seconds] = s.split(":").map(n => parseInt(n));
    if (isNaN(minutes) || isNaN(seconds)) return;
    return minutes * 60 + seconds;
  };

  const [inputValue, setInputValue] = useState(secondsToString(value));
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    setInputValue(secondsToString(value));
    setIsRunning(false);
  }, [value]);

  const attemptWrite = (newValue: number) => writeValue(newValue).catch(() => setInputValue(secondsToString(value)));

  useEffect(() => {
    const currentSeconds = stringToSeconds(inputValue);
    if (isUndefined(currentSeconds)) return;
    const intervalId = isRunning
      ? setInterval(() => setInputValue(secondsToString(currentSeconds + 1)), 1000)
      : undefined;
    return () => clearInterval(intervalId);
  }, [inputValue, isRunning]);

  const onEnd = () => {
    setIsRunning(false);
    const newValue = stringToSeconds(inputValue);
    newValue && attemptWrite(newValue);
  };

  const onInputChange = (stringValue: string) => {
    const [minutesString, secondsString] = stringValue.split(":");
    if (isUndefined(secondsString) || secondsString.length > 2) return;
    setInputValue(stringValue);

    const minutes = parseInt(minutesString);
    const seconds = parseInt(secondsString);
    if (isNaN(minutes) || isNaN(seconds) || seconds >= 60) return;
    if (minutes.toString() !== minutesString || pad(seconds.toString(), 2, "0") !== secondsString) return;

    attemptWrite(minutes * 60 + seconds);
  };

  const reset = () => {
    setInputValue(secondsToString(0));
    attemptWrite(0);
  };
  const resetButton = (
    <Button startIcon={<ReplayIcon />} onClick={reset} disabled={disabled} size={compact ? "small" : undefined}>
      Reset
    </Button>
  );

  return (
    <Stack gap={compact ? 1 : 2} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <ObjectiveValueInput
          value={inputValue}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
          compact={compact}
          sx={{ flexShrink: 0 }}
        />
        {compact && resetButton}
      </Stack>
      <Stack direction="row" gap={compact ? 1 : 2}>
        <Button
          variant="contained"
          startIcon={<PlayArrowIcon />}
          onClick={() => setIsRunning(true)}
          sx={{ width: 150 }}
          disabled={disabled}
        >
          Start
        </Button>
        <Button variant="contained" startIcon={<StopIcon />} onClick={onEnd} sx={{ width: 150 }} disabled={disabled}>
          Stop
        </Button>
      </Stack>
      {!compact && resetButton}
      {status && !compact && <StatusIcon status={status} />}
    </Stack>
  );
};

export default DurationInput;
