import { CobrowsingSessionDetails } from "@parallel/vertex/types/meeting.types";
import "@parallel/vertex/types/whiteboard.types";
import { BaseAPI } from "./base.api";

export class CobrowsingAPI extends BaseAPI {
  public prepareCobrowsingSession = (roomId: string, userId: string): Promise<CobrowsingSessionDetails> =>
    this.instance.post(`/cobrowsing`, { roomId, userId }).then(r => r.data);

  public endCobrowsingSession = (sessionId: string): Promise<void> =>
    this.instance.post(`/cobrowsing/end`, { sessionId }).then(r => r.data);
}
