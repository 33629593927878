import { useParams } from "react-router";
import { useDaily } from "@daily-co/daily-react";
import DailyMeeting from "@/components/meeting/daily/DailyMeeting";
import { MeetingRoomLayout } from "@/components/meeting/layout/MeetingLayout";
import { MeetingPlaceholderContainer } from "@/components/meeting/layout/MeetingPlaceholder";
import { useValidMeetingAccess } from "@/utils/hooks/use-valid-access";

export const MeetingRoomScreen = () => {
  const { roomId } = useParams();
  const props = useValidMeetingAccess();
  const dailyCall = useDaily();

  let content = <MeetingPlaceholderContainer>Cannot display meeting room</MeetingPlaceholderContainer>;
  if (!props) content = <MeetingPlaceholderContainer>Invalid Access - Redirecting</MeetingPlaceholderContainer>;

  if (!!roomId && !!props && !!dailyCall)
    content = <DailyMeeting dailyCall={dailyCall} meetingKey={roomId} loadingText="Connecting to Room" {...props} />;

  return <MeetingRoomLayout roomId={roomId}>{content}</MeetingRoomLayout>;
};

export default MeetingRoomScreen;
