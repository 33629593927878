import { useCallback } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { debounce, omit } from "lodash";
import { Override } from "@parallel/vertex/types/shared.types";

type AutoSaveTextFieldProps = Override<
  TextFieldProps,
  { value: string; onSave: (values: string) => Promise<unknown>; saveKey: string }
>;

const AutoSaveTextField = (props: AutoSaveTextFieldProps) => {
  const debouncedSave = useCallback(debounce(props.onSave, 400), [props.saveKey]);
  return (
    <TextField
      {...omit(props, "onSave", "saveKey")}
      onChange={e => {
        props.onChange && props.onChange(e);
        debouncedSave(e.target.value);
      }}
    />
  );
};

export default AutoSaveTextField;
