import { useMemo } from "react";
import { useLocation } from "react-router";

/**
 * a React hook that generates an object containing the query params of the current url
 *  e.g. if the URL is app.parallellearning.com?param1=a&param2=b, this returns a `query` instance where
 *  query.get('param1') === 'a' && query.get('param2') === 'b'
 *  for additional usage, see the `URLSearchParam` docs @ 'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams'
 **/
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
